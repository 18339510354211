import * as DateFNS from 'date-fns'
import { endOfMonth, intervalToDuration, startOfMonth } from 'date-fns'
import React from 'react'
import i18n from 'i18next'
import {
  find,
  flatMap,
  intersection,
  isEmpty,
  isEqual,
  isNull,
  lowerFirst,
  map,
  mapValues,
  omit,
  startCase,
  toLower,
  toNumber,
  uniq,
} from 'lodash'
import * as IbanKit from 'ibankit'
import { matchPath } from 'react-router-dom'
import { EMPTY_CURRENCY_VOLUME, initialTransactionPageState } from '../../constants'

import {
  ActionSignatureStatus,
  ActionStatus,
  Address,
  BankableEntityContractInputType,
  BatchPaymentStatusType,
  BusinessRegion,
  CardPaymentsAndAmp,
  CardsListQuery,
  CardSpendingLimitTypes,
  CompanyType,
  ContractType,
  ContractUbo,
  CreateOwnDocumentMutationVariables,
  CreateOwnDocumentTwoSidedMutationVariables,
  CreditTransaction,
  Currency,
  DebitTransaction,
  DirectDebitExecutionStatus,
  DirectDebitMandateStatus,
  Document,
  DocumentRelatedEntityType,
  DocumentType,
  getApolloClient,
  GetContractAccountsQuery,
  GetContractDetailsQuery,
  GetTransactionsQueryVariables,
  GetUboDetailsDocument,
  GetUboOwnersDetailsDocument,
  GetUboOwnersDocument,
  GetWalletTransactionsQueryVariables,
  Individual,
  LegalEntity,
  Maybe,
  NewAccountCurrency,
  PreAssessment,
  Products,
  RemoveDocumentMutationVariables,
  Scalars,
  SuccessFeePayoutStatus,
  Transaction,
  TransactionCardFilter,
  TransactionEntryType,
  TransactionRequest,
  TransactionRequestStatus,
  TransactionStatus,
  TransactionType,
  UboLegalEntityType,
  UboOwner,
  UboStakeType,
} from '../../graphql'
import {
  AccountFilterOptions,
  ActionReducerType,
  ActionsFilterTypesEnum,
  AuthPersonsSelectType,
  BatchSignTypes,
  BatchTrxReducerType,
  CardPaymentsSelectOptions,
  ContractManagementTablesEnum,
  CreateOwnDocMutationType,
  ExpectedVolumes,
  FilesForSavingAndShowType,
  FilterActions,
  KeyAndLabelSelectType,
  KeyLabelType,
  LegalList,
  PreparedContracUbo,
  ShortDateFormat,
  StatusSelectorType,
  TableSortPagesEnum,
  TransactionType as TrxType,
  TrxPageStateType,
  StepType,
  WalletTrxPageStateType,
} from '../../types'
import {
  BusinessCategoriesList,
  CountriesList,
  economicProfileAverageSizeTypesList,
  DocumentTypesList,
  economicProfileCompanySourceFundsTypesList,
  economicProfileTransferDescriptionTypesList,
  economicProfileTransferPayrollTypesList,
  PoiDocTypesMandatory,
  YesNoOptions,
  IntegrationTypeOptions,
  PciComplianceOptions,
  credentialDetailsList,
  countriesAndRegions,
} from '../Data'
import {
  actionsPageStateValue,
  cardsPageStateVar,
  clientsPageStateVar,
  contractsTeamPageStateVar,
  directDebitExecutionsStateValue,
  inboundMandatesStateValue,
  outboundMandatesStateValue,
  paymentsPageStateVar,
  poiNeeded,
  receivedPageStateVar,
  standingOrdersPageStateVar,
  trxPageStateVar,
  uploadDocsPageStateVar,
} from '../../graphql/local'
import { ApolloError, FetchResult, MutationFunctionOptions, ReactiveVar } from '@apollo/client'
import { toast } from 'react-toastify'
import { FormState } from 'react-hook-form'
import { BaseEnumWithKey, nationalitiesArray } from '../../components'
import { InputCodes } from '../../components/Common/InputCode/type'
import { documentTypes } from '@klarpay/enums'
import { formatDate } from '../dateUtils'
import { isIP } from 'is-ip'
import Bugsnag from '@bugsnag/js'
import { PreAssessmentOnboardingInput } from '../../schemes'
import { ManagementBusinessDetailsInput } from '../../schemes/ManagementBusinessDetails'
import {
  ONLY_LATIN_LETTERS_WITH_SPACES_BETWEEN_WORDS,
  PLEASE_UPLOAD_DOCUMENT_TYPE,
} from '../../constants/validations'
import config from '../../config'
import { parseWebsitesFormData } from '../index'
import { TFunction } from 'react-i18next'

export const dateConverter: (unformattedDate: string) => string = (unformatedDate) => {
  const dateConvert = new Date(unformatedDate)
  const date = JSON.stringify(dateConvert)
  return date.slice(1, 11).split('-').reverse().join('.')
}

function padValue(value: number) {
  return value < 10 ? '0' + value : value
}

export const dateFormat: (unformattedDate?: string, alterFormat?: boolean) => string = (
  unformattedDate,
  alterFormat = false,
) => {
  if (!unformattedDate) {
    return ''
  }
  const newDate = new Date(unformattedDate)

  const sMonth = padValue(newDate.getMonth() + 1)
  const sDay = padValue(newDate.getDate())
  const sYear = newDate.getFullYear()
  let sHour: string | number = +newDate.getHours()
  const sMinute = padValue(newDate.getMinutes())
  let sAMPM = 'AM'

  const iHourCheck = parseInt(String(sHour))

  if (+iHourCheck > 12 || +iHourCheck === 12) {
    sAMPM = 'PM'
    sHour = iHourCheck === 12 ? 12 : +iHourCheck - 12
  } else if (+iHourCheck === 0) {
    sHour = 12
  }

  sHour = padValue(+sHour)

  return alterFormat
    ? sHour + ':' + sMinute + ' ' + sAMPM + ' ' + sDay + '.' + sMonth + '.' + sYear
    : sDay + '.' + sMonth + '.' + sYear + ' ' + sHour + ':' + sMinute + ' ' + sAMPM
}

export const extractDateValue = (input?: Date | string | null): string | null => {
  if (!input) return null
  if (!(input instanceof Date)) return input.substring(0, 10)
  return DateFNS.format(input, 'yyyy-MM-dd')
}

export const refreshPage: () => void = () => {
  window.location.reload()
}

export const formatRights = (status: string, groupSize?: number): string => {
  switch (status) {
    case 'joint':
      return 'Joint (Dual)'
    case 'sole':
      return 'Sole'
    case 'group':
      return 'Group (' + groupSize + ')'
    case 'viewDataEntry':
      return 'Read and write'
    case 'viewOnly':
      return 'Read-only'
    default:
      return 'No rights!'
  }
}

// convert array of UBO with nested owners arr of each UBO
export async function personsNestedOwners(
  persons: ContractUbo[],
): Promise<Array<PreparedContracUbo>> {
  const client = getApolloClient()
  const result = []
  // const asyncIterable = {
  //   [Symbol.asyncIterator]() {
  //     return {
  //       i: 0,
  //       next() {
  //         if (this.i < persons.length) {
  //           return client
  //             .query({ query: GetUboOwnersDocument, variables: { id: persons[this.i].ubo.id } })
  //             .then((data) => {
  //               this.i++
  //               return { value: { ...persons[this.i], ownersArr: data }, done: false }
  //             })
  //         }
  //
  //         return Promise.resolve({ value: undefined, done: true })
  //       },
  //     }
  //   },
  // }

  // for await (const personUpdated of asyncIterable) {
  //   result.push(personUpdated)
  // }
  for (const person of persons) {
    const p = await client
      .query({ query: GetUboOwnersDocument, variables: { id: person?.ubo?.id } })
      .then((data) => {
        return { ...person, ownersArr: data.data.getUboOwners }
      })
    result.push(p)
  }
  return result
}

// for temporary use for transform owners data to necessary view
export function transData(dataArr: PreparedContracUbo[]): LegalList[] {
  const result: LegalList[] = []
  dataArr.forEach((value) => {
    if (value?.ubo?.entity?.__typename === 'LegalEntity' && !value?.addedFromExistingUbo) {
      result.push({
        key: `${value?.ubo?.id}`,
        label: value.ubo.entity.companyName as string,
        legalEntityType: value.ubo.entity.legalEntityType,
        ownersTotalShareSize: find(
          value.ownersArr,
          (t) => t.ownershipType === UboStakeType.ManagingDirector,
        )
          ? 100
          : value.ubo.ownersTotalShareSize,
      })
      if (value.ownersArr.length > 0 && !value?.addedFromExistingUbo) {
        value.ownersArr.forEach((val: ContractUbo | UboOwner) => {
          if (val?.ubo?.entity?.__typename === 'LegalEntity') {
            result.push({
              key: val.ubo.id as string,
              label: val.ubo.entity.companyName,
              legalEntityType: (val?.ubo?.entity as LegalEntity).legalEntityType,
              ownersTotalShareSize: find(
                val?.ubo?.ownersFlatList,
                (item) => item?.ownershipType === UboStakeType.ManagingDirector,
              )
                ? 100
                : val.ubo?.ownersTotalShareSize,
            })
          }
        })
      }
    }
  })
  return result
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const focusKeyPressNext = (e: React.KeyboardEvent, formState?: FormState<any>): void => {
  if (e.key === 'Enter') {
    if (
      formState &&
      (!formState.isValid ||
        !formState.isDirty ||
        (formState.isDirty && !isEmpty(formState.errors)))
    ) {
      e.preventDefault()
    }
    if (!formState) {
      e.preventDefault()
    }

    const forNextFocus = document.getElementById('forNextFocus')
    const inputs: HTMLInputElement[] = Array.from(
      forNextFocus?.querySelectorAll('input, select, textarea') ?? [],
    )
    const newArrayInputs = inputs.filter((item) => item.id !== 'prefixCode')
    const selectedInput = document.activeElement
    const indexOf = newArrayInputs.findIndex((item) => item.id === selectedInput?.id)
    if (indexOf + 1 < newArrayInputs.length) newArrayInputs[indexOf + 1].focus()
  }
}

export function sortTransactions(data: Transaction[]): Transaction[] {
  return [...data].sort((a, b) => {
    if (a?.status?.includes('pending') && !b?.status?.includes('pending')) {
      return -1
    }
    if (!a?.status?.includes('pending') && b?.status?.includes('pending')) {
      return 1
    }

    return 0
  })
}

export const copyToClipboardOld = (
  copyText: string,
  ref: HTMLDivElement | null = null,
  denySpaces = true,
): void => {
  const wrapper = document.createElement('textarea')
  if (ref) {
    wrapper.innerText = copyText
    ref.appendChild(wrapper)
  } else {
    wrapper.innerText = denySpaces ? copyText.replaceAll(' ', '') : copyText
    document.body.appendChild(wrapper)
  }
  wrapper.select()
  document.execCommand('copy')
  wrapper.remove()
}

export const copyToClipboard = async (
  copyText: string,
  ref: HTMLDivElement | null = null,
  denySpaces = true,
) => {
  if (navigator.clipboard && window.location.protocol.slice(0, -1) === 'https') {
    try {
      await navigator.clipboard.writeText(copyText)
    } catch {
      copyToClipboardOld(copyText, ref, denySpaces)
    }
  } else {
    copyToClipboardOld(copyText, ref, denySpaces)
  }
}

export const transactionType = (
  entryType: TransactionEntryType | string,
  accountId?: string,
): string => {
  switch (entryType) {
    case TransactionEntryType.Debit:
      return i18n.t('transactionTypeOutbound', 'Outbound')
    case TransactionEntryType.Credit:
      return i18n.t('transactionTypeInbound')
    case TransactionType.Fee:
      return i18n.t('fee', 'Fee')
    case TransactionType.Cashback:
      return i18n.t('cashback')
    default:
      return accountId === '#allAccounts'
        ? i18n.t('internal', 'Internal')
        : i18n.t('transactionTypeTransfer')
  }
}

export const getTransactionTypeName = (
  accountId: string | undefined,
  isInterContract: boolean,
  type: Maybe<TransactionType> | undefined,
  entryType: Maybe<TransactionEntryType> | undefined,
) => {
  return (accountId === '#allAccounts' &&
    type === TransactionType.TransferInternal &&
    !isInterContract) ||
    (type === TransactionType.Fee && !isInterContract) ||
    type === TransactionType.Cashback
    ? transactionType(type, accountId)
    : transactionType(entryType as TransactionEntryType, accountId)
}

export function normalizeActionStatus(status: ActionSignatureStatus | ActionStatus): string {
  switch (status) {
    case ActionSignatureStatus.Accept:
      return i18n.t('signed', 'Signed')
    case ActionSignatureStatus.Reject:
      return i18n.t('rejected', 'Rejected')
    default:
      return startCase(status)
  }
}

export function normalizeDirectDebitStatus(
  status: DirectDebitMandateStatus | DirectDebitExecutionStatus,
): string {
  switch (status) {
    case DirectDebitMandateStatus.New:
      return i18n.t('directDebitStatus_new', 'Waiting for signature')
    case DirectDebitMandateStatus.Approved:
      return i18n.t('active', 'Active')
    default:
      return startCase(status)
  }
}

export const shortFormatDate = (date: Date, options: ShortDateFormat, locale = 'en-GB'): string => {
  return date.toLocaleDateString(locale, options)
}

export function trxStatusNormalize(
  statusValue: TransactionStatus,
  trxType?: Maybe<TrxType>,
): string {
  switch (statusValue) {
    case TransactionStatus.PendingExecutionSignatures:
    case TransactionStatus.PendingCancellationSignatures:
      return 'pendingAction'
    case TransactionStatus.Cancelled:
      return 'cancelled'
    case TransactionStatus.Authorized:
    case TransactionStatus.PendingAml:
      return 'processing'
    case TransactionStatus.Completed:
    case TransactionStatus.CompletedManually:
      return trxType === TrxType.Credit ? 'received' : 'sent'
    case TransactionStatus.Reverted:
      return 'reverted'
    case TransactionStatus.Declined:
      return 'declined'
    case TransactionStatus.Scheduled:
      return 'scheduled'
    default:
      return statusValue
  }
}

export function trxRequestStatusNormalize(statusValue: TransactionRequestStatus): string {
  switch (statusValue) {
    case TransactionRequestStatus.PendingExecutionSignatures:
      return 'pendingAction'
    case TransactionRequestStatus.Cancelled:
      return 'cancelled'
    case TransactionRequestStatus.Processing:
      return 'processing'
    case TransactionRequestStatus.Completed:
      return 'sent'
    case TransactionRequestStatus.Rejected:
      return 'declined'
    case TransactionRequestStatus.PendingAml:
      return 'waitingForDocuments'
    default:
      return statusValue
  }
}

export function batchStatusNormalize(statusValue: BatchPaymentStatusType): string {
  switch (statusValue) {
    case BatchPaymentStatusType.PendingExecutionSignatures:
      return 'pending'
    case BatchPaymentStatusType.Cancelled:
      return 'canceled'
    case BatchPaymentStatusType.ExecutedWithFailure:
      return 'failed'
    case BatchPaymentStatusType.Signed:
      return 'processing'
    case BatchPaymentStatusType.Executed:
      return 'sent'
    default:
      return 'canceled'
  }
}

export const rightsTeamRole = (role: string, groupSize?: number): string => {
  switch (role) {
    case 'joint':
      return i18n.t('signatoryJoint', 'Signatory (Joint, Dual)')
    case 'sole':
      return i18n.t('signatorySole', 'Signatory (Sole)')
    case 'group':
      return groupSize
        ? i18n.t('signatoryJointGroupSize', 'Signatory (Joint, Group, {{groupSize}})', {
            groupSize,
          })
        : i18n.t('signatoryJointGroup', 'Signatory (Joint, Group)')
    case 'viewDataEntry':
      return i18n.t('limitedViewDataEntry', 'Limited access (View and Data Entry)')
    case 'viewOnly':
      return i18n.t('limitedViewOnly', 'Limited access (View Only)')
    case 'cardHolder':
      return i18n.t('cardholder', 'Cardholder')
    case 'paymentFacilitationView':
      return i18n.t('paymentFacilitationView', 'Limited access (Payment Facilitation View)')
    default:
      return ''
  }
}

export const GetActionsFilterLabel = (type: ActionsFilterTypesEnum) => {
  switch (type) {
    case ActionsFilterTypesEnum.Document:
      return i18n.t('document', 'Document')
    case ActionsFilterTypesEnum.ContractChange:
      return i18n.t('contractChange', 'Contract change')
    case ActionsFilterTypesEnum.Transfer:
      return i18n.t('transfer', 'Transfer')
    case ActionsFilterTypesEnum.BatchPayment:
      return i18n.t('batchPayment', 'Batch Payment')
    case ActionsFilterTypesEnum.NewAuthorizedPerson:
      return i18n.t('authPerson', 'New authorized person')
    case ActionsFilterTypesEnum.DeactivatePerson:
      return i18n.t('deactivatePerson', 'Deactivate person')
    case ActionsFilterTypesEnum.AccountChange:
      return i18n.t('accChange', 'Account change')
    case ActionsFilterTypesEnum.DirectDebit:
      return i18n.t('directDebit', 'Direct Debit')
    case ActionsFilterTypesEnum.PaymentFacilitation:
      return i18n.t('paymentFacilitation', 'Payment facilitation')
    default:
      return ''
  }
}

export const cardSpendingLimitTypes = (
  entryType: CardSpendingLimitTypes | string | undefined,
): string => {
  switch (entryType) {
    case CardSpendingLimitTypes.DailyContactlessPurchase:
      return i18n.t('dailyContactlessPurchase', 'Contactless Daily')
    case CardSpendingLimitTypes.DailyInternetPurchase:
      return i18n.t('dailyInternetPurchase', 'Internet Daily')
    case CardSpendingLimitTypes.DailyPurchase:
      return i18n.t('dailyPurchase', 'Overall Daily')
    case CardSpendingLimitTypes.DailyWithdrawal:
      return i18n.t('dailyWithdrawal', 'Withdrawal Daily')
    case CardSpendingLimitTypes.MonthlyContactlessPurchase:
      return i18n.t('monthlyContactlessPurchase', 'Contactless Monthly')
    case CardSpendingLimitTypes.MonthlyInternetPurchase:
      return i18n.t('monthlyInternetPurchase', 'Internet Monthly')
    case CardSpendingLimitTypes.MonthlyPurchase:
      return i18n.t('monthlyPurchase', 'Overall Monthly')
    case CardSpendingLimitTypes.MonthlyWithdrawal:
      return i18n.t('monthlyWithdrawal', 'Withdrawal Monthly')
    case CardSpendingLimitTypes.TransactionContactlessPurchase:
      return i18n.t('transactionContactlessPurchase', 'Contactless Transaction')
    case CardSpendingLimitTypes.TransactionInternetPurchase:
      return i18n.t('transactionInternetPurchase', 'Internet Transaction')
    case CardSpendingLimitTypes.TransactionPurchase:
      return i18n.t('transactionPurchase', 'Transaction')
    case CardSpendingLimitTypes.TransactionWithdrawal:
      return i18n.t('transactionWithdrawal', 'Withdrawal Transaction')
    // case CardSpendingLimitTypes.WeeklyContactlessPurchase:
    //   return i18n.t('weeklyContactlessPurchase', 'Contactless Weekly')
    // case CardSpendingLimitTypes.WeeklyInternetPurchase:
    //   return i18n.t('weeklyInternetPurchase', 'Internet Weekly')
    // case CardSpendingLimitTypes.WeeklyPurchase:
    //   return i18n.t('weeklyPurchase', 'Overall Weekly')
    // case CardSpendingLimitTypes.WeeklyWithdrawal:
    //   return i18n.t('weeklyWithdrawal', 'Withdrawal Weekly')

    default:
      return entryType as string
  }
}

export function isEditingPoiNeeded(
  authorizedPerson: number,
  authorizedPersonsList: AuthPersonsSelectType[],
  filesForUpload: FilesForSavingAndShowType[],
  authStatus: string,
  trigger: (name?: string | string[]) => void,
): void {
  if (
    filesForUpload?.find(
      (item) => PoiDocTypesMandatory.includes(item?.docType) && !item.isRemoved,
    ) ||
    ((find(authorizedPersonsList, { key: authorizedPerson }) as AuthPersonsSelectType)
      ?.isAuthorizedSignatory === 'true' &&
      authStatus === 'existing')
  ) {
    poiNeeded(false)
    trigger(`documentType`)
  } else {
    poiNeeded(true)
  }
}

export function createOwnDoc(
  uboId: string,
  ownedUboId: string | undefined,
  createOwnDocMutationType: CreateOwnDocMutationType,
  formData: { filesForUpload: FilesForSavingAndShowType[] },
  createOwnDocument: (
    options?: MutationFunctionOptions<
      { __typename?: 'KlarpayMutations' } & {
        createOwnDocument?: Maybe<{ __typename?: 'Document' } & Pick<Document, 'id'>>
      },
      CreateOwnDocumentTwoSidedMutationVariables
    >,
  ) => Promise<
    FetchResult<
      { __typename?: 'KlarpayMutations' } & {
        createOwnDocument?: Maybe<{ __typename?: 'Document' } & Pick<Document, 'id'>>
      }
    >
  >,
  createOwnDocumentOneSide: (
    options?: MutationFunctionOptions<
      { __typename?: 'KlarpayMutations' } & {
        createOwnDocument?: Maybe<{ __typename?: 'Document' } & Pick<Document, 'id'>>
      },
      CreateOwnDocumentMutationVariables
    >,
  ) => Promise<
    FetchResult<
      { __typename?: 'KlarpayMutations' } & {
        createOwnDocument?: Maybe<{ __typename?: 'Document' } & Pick<Document, 'id'>>
      }
    >
  >,
  applicationId: Scalars['ID']['input'],
  individual: Individual,
  removeDocument: (
    options?: MutationFunctionOptions<
      { __typename?: 'KlarpayMutations' } & {
        removeDocument?: Maybe<{ __typename?: 'Document' } & Pick<Document, 'id' | 'type'>>
      },
      RemoveDocumentMutationVariables
    >,
  ) => Promise<
    FetchResult<
      { __typename?: 'KlarpayMutations' } & {
        removeDocument?: Maybe<{ __typename?: 'Document' } & Pick<Document, 'id' | 'type'>>
      }
    >
  >,
): void {
  const refetchQueriesArr = [
    uboId &&
    (createOwnDocMutationType === CreateOwnDocMutationType.addContractUbo ||
      createOwnDocMutationType === CreateOwnDocMutationType.updateContractUbo)
      ? {
          query: GetUboDetailsDocument,
          variables: { contractUboId: uboId },
        }
      : '',
    ownedUboId &&
    (createOwnDocMutationType === CreateOwnDocMutationType.addUboOwner ||
      createOwnDocMutationType === CreateOwnDocMutationType.updateUboOwner)
      ? {
          query: GetUboOwnersDetailsDocument,
          variables: {
            id: ownedUboId,
          },
        }
      : '',
  ]
  const firstSide = formData.filesForUpload.find(
    (docFile) => docFile.isFirstSide === true && !docFile.isRemoved,
  )
  const secondSide = formData.filesForUpload.find(
    (docFile) => docFile.isFirstSide === false && !docFile.isRemoved,
  )
  if (formData.filesForUpload && formData.filesForUpload.length > 0) {
    ;(async () => {
      if (!!secondSide) {
        await createOwnDocument({
          variables: {
            type: mainDocTypeForSide(firstSide?.docType as DocumentType),
            firstFileName: firstSide?.fileName as string,
            secondFileName: secondSide?.fileName as string,
            firstPlainUrl: firstSide?.plainUrl ?? '',
            secondPlainUrl: secondSide?.plainUrl ?? '',
            firstSize: firstSide?.size as number,
            secondSize: secondSide?.size as number,
            contractId: +applicationId,
            relatedEntityId: individual.id as Scalars['ID']['input'],
            relatedEntityType: DocumentRelatedEntityType.Individual,
            expiration: extractDateValue(firstSide?.expiration),
          },
          refetchQueries: refetchQueriesArr,
          awaitRefetchQueries: true,
        }).catch((e) => {
          toast.error(
            i18n.t(
              'errorDocumentUpload',
              'Unable to upload document at the moment, please try again later',
            ),
          )
          reportAppError(e)
        })
      } else {
        await createOwnDocumentOneSide({
          variables: {
            type: mainDocTypeForSide(firstSide?.docType as DocumentType),
            fileName: firstSide?.fileName as string,
            plainUrl: firstSide?.plainUrl ?? '',
            size: firstSide?.size as number,
            contractId: +applicationId,
            relatedEntityId: individual?.id as Scalars['ID']['input'],
            relatedEntityType: DocumentRelatedEntityType.Individual,
            expiration: extractDateValue(firstSide?.expiration),
          },
          refetchQueries: refetchQueriesArr,
          awaitRefetchQueries: true,
        }).catch((e) => {
          toast.error(
            i18n.t(
              'errorDocumentUpload',
              'Unable to upload document at the moment, please try again later',
            ),
          )
          reportAppError(e)
        })
      }
    })()
  }
  const removedDoc = formData.filesForUpload.find((docFile) => docFile.id !== 0)
  ;(async () => {
    removedDoc &&
      (await removeDocument({
        variables: {
          id: removedDoc.id,
        },
        refetchQueries: refetchQueriesArr,
        awaitRefetchQueries: true,
      }))
  })()
}

export const convertingTimerSecondsToMinutes = (seconds: number): string => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
  return `${duration.minutes?.toString().padStart(2, '0')}:${duration.seconds
    ?.toString()
    .padStart(2, '0')}`
}

export const contractChangeTypes = (property: string): string => {
  const otherValues = [
    'sourceOfFundsDescription',
    'inboundSourcesOther',
    'outboundDestinationsOther',
  ]

  if (otherValues.includes(property)) return i18n.t('other', 'Other')
  switch (lowerFirst(property)) {
    case 'additionalDetails':
      return i18n.t('additionalDetails', 'Additional Details (optional)')
    case 'city':
      return i18n.t('city', 'City')
    case 'countryDescription':
    case 'country':
      return i18n.t('country', 'Country')
    case 'streetAddressCorrespondence':
      return i18n.t('line1Correspondence', 'Street Address correspondence')
    case 'postalCodeCorrespondence':
      return i18n.t('postalCodeCorrespondence', 'Postal Code correspondence')
    case 'cityCorrespondence':
      return i18n.t('cityCorrespondence', 'City correspondence')
    case 'streetAddress':
    case 'line1':
      return i18n.t('line1', 'Street Address')
    case 'postalCode':
    case 'zip':
      return i18n.t('zip', 'Postal Code')
    case 'companyName':
      return i18n.t('companyName', 'Company Name')
    case 'tradingName':
      return i18n.t('tradingName2', 'Trading Name')
    case 'phone':
    case 'Phone':
    case 'phoneNumber':
      return i18n.t('businessPhone', 'Business phone')
    case 'website':
      return i18n.t('websites', 'Websites')
    case 'estimateOutbound':
    case 'estimatedOutgoingTransactionsAmount':
      return i18n.t(
        'numberOutboundTransactions',
        'Estimated number of outbound transactions per month',
      )
    case 'estimateInbound':
    case 'estimatedIncomingTransactionsAmount':
      return i18n.t(
        'numberInboundTransactions',
        'Estimated number of inbound transactions per month',
      )
    case 'expectedVolumes':
      return i18n.t('expectedVolumes', 'Expected Volumes')
    case 'taxId':
      return i18n.t('taxId', 'Tax ID')
    case 'businessRelationshipWithCompany':
      return i18n.t(
        'businessRelationship',
        'Do you hold other business relationships with Bivial?',
        {
          val1: config.name,
        },
      )
    case 'businessRelationshipWithCompanyExplanation':
      return i18n.t('pleaseSpecify', 'Please specify')
    case 'affiliatesUsed':
      return i18n.t('listAffiliates', 'Please list the Affiliates you use')
    case 'businessSector':
      return i18n.t('businessCategory', 'Business Category')
    case 'businessSectorClientsYouWorkWith':
      return i18n.t(
        'underlyingClientsOrFirstTestClient',
        'Underlying clients or the first Test Client you want to process with Bivial (Name and URL)',
      )
    case 'products':
      return i18n.t('productsRequested', 'Products Requested')
    case 'conductingKYC':
    case 'businessSectorConductingKYC':
    case 'businessSectorConductingClientKYC':
      return i18n.t(
        'businessSectorConductingKYC',
        'Do you conduct KYC on your content providers / publishers / advertisers?',
      )
    case 'conductPublisherContentMonitoring':
    case 'businessSectorConductPublisherContentMonitoring':
      return i18n.t(
        'businessSectorConductPublisherContentMonitoring',
        'Do you conduct Publisher content monitoring?',
      )
    case 'anticipatedAccountActivity':
      return i18n.t('anticipatedAccountActivity', 'Anticipated Account Activities')
    case 'sourceOfFunds':
      return i18n.t('sourceOfFunds', 'Source of Funds')
    case 'relevantCredentialOnFilesBillingSalesMethod':
      return i18n.t('tickRelevant', 'Tick the relevant and supply the further details')
    case 'credentialDetailsExplanation':
      return i18n.t(
        'cofDetails',
        'Please provide details on how you use the COF billing sales method',
      )
    case 'integrationTypeRequired':
      return i18n.t('integrationType', 'Integration type required')
    case 'urls':
      return i18n.t('websites', 'Websites')
    case 'dssCompliance':
    case 'pciDssCompliance':
      return i18n.t('pciCompliance', 'PCI DSS Compliance')
    case 'pciProviderName':
      return i18n.t('providerName', 'Your PCI provider`s name')
    case 'nameOfCurrentFormerProcessor':
      return i18n.t('nameOfCurrentFormerProcessor', 'Name of current / former processor(s)')
    case 'reasonForLeavingCurrentProcessor':
      return i18n.t(
        'reasonForLeavingCurrentProcessor',
        'Reason for leaving current processor(s) (if any)',
      )
    case 'periodOfTimeWithCurrentFormerProcessor':
      return i18n.t('periodWithProcessor', 'Period of time with current/former processor(s)')
    case 'processingHistoryStatement':
      return i18n.t(
        'officialProcessingHistory',
        'Official processing history statement from your current/former processor(s):',
      )
    case 'regulatorNameAndWebsite':
      return i18n.t('regulatoryInfoName', 'Regulator Name and Website')
    case 'licenseNumber':
      return i18n.t('regulatoryInfoLicenseNumber', 'License Number (if obtained)')
    case 'companyAnnualIncome':
      return i18n.t('companyAnnualIncome', 'Company Annual Income')
    case 'companyAnnualIncomeCurrency':
      return i18n.t('companyAnnualIncomeCurrency', 'Company Annual Income Currency')
    case 'estimatedIncomingTransactions':
      return i18n.t('estimatedIncomingTransactions', 'Estimated Incoming Transactions')
    case 'estimatedOutgoingTransactions':
      return i18n.t('estimatedOutgoingTransactions', 'Estimated Outgoing Transactions')
    case 'isCardPayment':
      return i18n.t('isCardPayment', 'Card payment')
    case 'useAffiliateForGeneratingTraffic':
      return i18n.t(
        'useAffiliateForGeneratingTraffic',
        'Do you use Affiliates for generating traffic?',
      )
    case 'businessRegions':
      return i18n.t('businessRegions', 'Business Regions')
    case 'trxCurrencies':
      return i18n.t('TransactionCurrencies', 'Transaction currencies')
    case 'settlementCurrencies':
      return i18n.t('requiredSettlementCurrencies', 'Required settlement currencies')
    case 'currentProcessor':
      return i18n.t('nameOfCurrentProcessors', 'Name of current / former processor(s)')
    case 'reasonLeavingProcessor':
      return i18n.t(
        'reasonForLeavingCurrentProcessor',
        'Reason for leaving current processor(s) (if any)',
      )
    case 'selAffiliatesTraffic':
      return i18n.t(
        'doYouUseAffiliatesForGeneratingTraffic',
        'Do you use Affiliates for generating traffic?',
      )
    case 'integrationTypeSelect':
      return i18n.t('integrationTypeRequired', 'Integration type required')
    case 'pciProvidersName':
      return i18n.t('yourPciProviderName', 'Your PCI provider`s name')
    case 'selCredentialBillingMethod':
      return i18n.t(
        'doYouUseCredentialFileCOF',
        'Do you use a "Credential on File" (COF) billing sales method?',
      )
    case 'volumesVisa':
      return i18n.t('volumesOfVisa', 'Total projected monthly Visa volume')
    case 'volumesMaster':
      return i18n.t('volumesOfMasterCard', 'Total projected monthly Master Card volume')
    case 'apmCurrently':
      return i18n.t('apmCurrentlyInUse', 'APM(s) currently in use')
    case 'apmRequired':
      return i18n.t('requiredApm', 'Required APM')
    case 'countriesOfOperatingAndConductingBusiness':
      return i18n.t('countriesOfOperation', 'Countries of operation')
    case 'processPaymentCardsBefore':
      return i18n.t('processPaymentCardsBefore', 'Process payment cards before')
    default:
      return i18n.t(property as string)
  }
}

export const mainDocTypeForSide = (docType: DocumentType): DocumentType => {
  switch (docType) {
    case DocumentType.IdBack:
      return DocumentType.Id
    case DocumentType.IdFront:
      return DocumentType.Id
    case DocumentType.DrivingLicenseBack:
      return DocumentType.DrivingLicense
    case DocumentType.DrivingLicenseFront:
      return DocumentType.DrivingLicense
    default:
      return docType
  }
}

export function showError(error: ApolloError): void {
  toast.error(i18n.t('errorFetchingData', 'Error fetching data'))
  toast.error(error.message)
}

export const addressToString = (address?: Address | null): string => {
  const { line1, city, region, zip, country, additionalDetails } = address || {}
  const countryFullName = CountriesList?.find((v: BaseEnumWithKey) => v.key === country)?.label
  return [line1, zip, city, region, countryFullName, additionalDetails]
    .filter((f) => !!f)
    .join(', ')
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const currentDateStatement = () => {
  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() - 1)
  const start = startOfMonth(currentDate)
  const end = endOfMonth(currentDate)
  const dateArr: [Date, Date] = [start, end]
  return dateArr
}

export const editedValueViewCorrectly = (key: string, data: Individual | null): string => {
  switch (key) {
    case 'phone':
      return data && (data[key as keyof Individual] || '').replaceAll(' ', '')
    case 'nationality':
      return (
        (data &&
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          (nationalitiesArray as Array<any> | AuthPersonsSelectType[])?.find(
            (v) => v.key === data[key as keyof Individual],
          )?.value) ||
        ''
      )
    default:
      return (data && data[key as keyof Individual]) || ''
  }
}

export const negativeCheck = (value: string | number | undefined | null): string => {
  const balance = toNumber(value)
  if (value === null || isNaN(balance) || balance >= 0) {
    return ''
  } else {
    return 'negative'
  }
}

export const checkFullCode = (value: InputCodes): boolean => {
  const valuesCode = Object.values(value)
  const arr = []
  for (let i = 0; i < valuesCode.length; i++) {
    if (valuesCode[i] !== '') {
      arr.push(valuesCode[i])
    }
  }

  return arr.length === 6
}

export function batchTrxReducer(
  state: BatchSignTypes,
  action: BatchTrxReducerType,
): BatchSignTypes {
  switch (action.type) {
    case 'setCheckAll':
      return { ...state, checkedAll: action.payload } as BatchSignTypes
    case 'setBatchSign':
      return {
        ...state,
        batchTrxSign: state.batchTrxSign.concat(action.payload as string | string[]),
      }
    case 'setAllBatchSign':
      return {
        ...state,
        batchTrxSign: action.payload,
      } as BatchSignTypes
    case 'removeBatchSign':
      return {
        ...state,
        batchTrxSign: action.payload,
      } as BatchSignTypes
    default:
      throw new Error()
  }
}

export function filtersReducer(
  state: TrxPageStateType,
  action: ActionReducerType,
): TrxPageStateType {
  switch (action.type) {
    case FilterActions.Search:
      return { ...state, searchValue: action.payload } as TrxPageStateType
    case FilterActions.Direction:
      return { ...state, direction: action.payload } as TrxPageStateType
    case FilterActions.CardFilter:
      return { ...state, cardsFilter: action.payload } as TrxPageStateType
    case FilterActions.Status:
      return { ...state, status: action.payload } as TrxPageStateType
    case FilterActions.Period:
      return { ...state, period: action.payload } as TrxPageStateType
    case FilterActions.CardId:
      return { ...state, cardId: action.payload } as TrxPageStateType
    case FilterActions.SortBy:
      return { ...state, sortBy: action.payload } as TrxPageStateType
    case FilterActions.SortDirection:
      return { ...state, sortDirection: action.payload } as TrxPageStateType
    case 'reset':
      return initialTransactionPageState
    default:
      throw new Error()
  }
}

export function moderateTrxParams(
  filtersState: TrxPageStateType,
  queryParams: GetTransactionsQueryVariables,
  isCardholder: boolean,
): GetTransactionsQueryVariables {
  const updatedParams = { ...queryParams }

  if (filtersState.direction) {
    switch (filtersState.direction) {
      case 'allDirections':
        updatedParams.entryTypes = [TransactionEntryType.Credit, TransactionEntryType.Debit]
        delete updatedParams.types
        break
      case TransactionEntryType.Debit:
      case TransactionEntryType.Credit:
        updatedParams.entryTypes = filtersState.direction
        updatedParams.notTypes = [TransactionType.Fee]
        break
      case TransactionType.Fee:
      case TransactionType.TransferInternal:
      case TransactionType.Cashback:
        updatedParams.types = filtersState.direction
        break
      default:
        updatedParams.entryTypes = [TransactionEntryType.Credit, TransactionEntryType.Debit]
        delete updatedParams.types
    }
  }

  if (filtersState.searchValue) {
    updatedParams.search = filtersState.searchValue
      .split(' ')
      .filter((item) => !['to', 'from'].includes(item.toLowerCase()))
      .join(' ')
  } else {
    delete updatedParams.search
  }

  if (!!filtersState.period[0] && !!filtersState.period[1]) {
    const fromDate = new Date(formatDate(filtersState.period[0], true)).toISOString()
    const toDate = new Date(formatDate(filtersState.period[1], true)).toISOString()
    updatedParams.createdAtFrom = fromDate
    updatedParams.createdAtTo = toDate
  } else {
    delete updatedParams.createdAtFrom
    delete updatedParams.createdAtTo
  }

  if (filtersState.status) {
    switch (filtersState.status) {
      case StatusSelectorType.AllStatuses:
        delete updatedParams.statuses
        break
      case StatusSelectorType.Received:
        updatedParams.entryTypes = TransactionEntryType.Credit
        updatedParams.statuses = [
          TransactionStatus.Completed,
          TransactionStatus.CompletedManually,
          TransactionStatus.Authorized,
        ]
        break
      case StatusSelectorType.Sent:
        updatedParams.entryTypes = TransactionEntryType.Debit
        updatedParams.statuses = [
          TransactionStatus.Completed,
          TransactionStatus.CompletedManually,
          TransactionStatus.Authorized,
        ]
        break
      case StatusSelectorType.Processing:
        updatedParams.statuses = [TransactionStatus.PendingAml, TransactionStatus.AuthorizedFx]
        break
      case StatusSelectorType.Pending:
        updatedParams.statuses = [
          TransactionStatus.PendingExecutionSignatures,
          TransactionStatus.PendingCancellationSignatures,
          TransactionStatus.Scheduled,
        ]
        break
      case StatusSelectorType.Reverted:
        updatedParams.statuses = TransactionStatus.Reverted
        break
      case StatusSelectorType.Canceled:
        updatedParams.statuses = TransactionStatus.Cancelled
        break
      case StatusSelectorType.Declined:
        updatedParams.statuses = TransactionStatus.Declined
        break
      case StatusSelectorType.Scheduled:
        updatedParams.statuses = TransactionStatus.Scheduled
        break
      default:
        delete updatedParams.statuses
    }
  }

  if (filtersState.notStatus) {
    switch (filtersState.notStatus) {
      case 'pending':
        updatedParams.notStatuses = [
          TransactionStatus.PendingExecutionSignatures,
          TransactionStatus.PendingCancellationSignatures,
          TransactionStatus.Scheduled,
        ]
        break
      default:
        delete updatedParams.notStatuses
    }
  }

  if (
    filtersState.cardId !== AccountFilterOptions.allMethods &&
    filtersState.cardId !== AccountFilterOptions.bankTransfer &&
    filtersState.cardId !== AccountFilterOptions.noActiveCards
  ) {
    updatedParams.cardId = filtersState.cardId
  }

  if (filtersState.cardId === AccountFilterOptions.bankTransfer) {
    updatedParams.cardFilter = TransactionCardFilter.ExcludeCards
  }
  if (isCardholder) {
    updatedParams.cardFilter = TransactionCardFilter.OnlyCards
  }

  return updatedParams
}

export function getMaskedCardNumber(apiMaskedCardNumber: Maybe<string> | undefined): string {
  return apiMaskedCardNumber ? `${i18n.t('card', 'Card')} *${apiMaskedCardNumber?.slice(-4)}` : ''
}

export function getTransactionMethod(data: CreditTransaction | DebitTransaction): string {
  if (data.card) {
    return getMaskedCardNumber(data.card.apiMaskedCardNumber)
  }
  if (data.type === TransactionType.Card) {
    return i18n.t('card', 'Card')
  }
  if (data.type === TransactionType.TransferCrypto) {
    return i18n.t('cryptoTransfer', 'Crypto transfer')
  }
  return i18n.t('bankTransfer', 'Bank transfer')
}

export const setValueIntoStore = (
  dispatch: React.Dispatch<ActionReducerType>,
  type: FilterActions,
  value: string,
) => {
  dispatch({ type, payload: value })
}

export const prepareIpsArray = (stringOfIps: string) =>
  stringOfIps
    .split(',')
    .map((ip: string) => ip.replaceAll(',', '').trim())
    .filter((item: string) => item)

export function getDocumentLabelByType(type: string): string {
  return find(documentTypes, (doc) => doc.key === type)?.label || i18n.t('none', 'None')
}

export const hasInvalidIps = (ips: string[]) => {
  return ips.some((ip) => {
    const [ipAddress, maskLength] = ip.split('/')
    const validMaskLength = !maskLength || (+maskLength >= 0 && +maskLength <= 32)
    const validIpAddress = isIP(ipAddress)
    const isValid = validMaskLength && validIpAddress
    return !isValid
  })
}

export const capitalizeString = (str = ''): string => startCase(toLower(str))

export const isQRRIban = (iban?: string): boolean => {
  const QR_IBAN_PREFIX_LOWER_BOUND = 30000
  const QR_IBAN_PREFIX_UPPER_BOUND = 31999
  if (!iban) return false
  const isQRRSupportedCountry = iban?.startsWith('CH') || iban?.startsWith('LI')
  const ibanBankCode = +iban?.substring(4, 9)
  return (
    isQRRSupportedCountry &&
    ibanBankCode >= QR_IBAN_PREFIX_LOWER_BOUND &&
    ibanBankCode <= QR_IBAN_PREFIX_UPPER_BOUND
  )
}

export const getSortStateVarName = (
  activePage: string,
  contractManagementType: ContractManagementTablesEnum = ContractManagementTablesEnum.ReceivedDocs,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ReactiveVar<any> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let variable: ReactiveVar<any> = actionsPageStateValue

  if (activePage.includes(TableSortPagesEnum.transactions)) {
    variable = trxPageStateVar
  }

  if (activePage.includes(TableSortPagesEnum.clients)) {
    variable = clientsPageStateVar
  }

  if (activePage.includes(TableSortPagesEnum.contract)) {
    variable = receivedPageStateVar

    if (contractManagementType === ContractManagementTablesEnum.Team) {
      variable = contractsTeamPageStateVar
    }

    if (contractManagementType === ContractManagementTablesEnum.UploadedDocs) {
      variable = uploadDocsPageStateVar
    }
  }

  if (activePage.includes(TableSortPagesEnum.cards)) {
    variable = cardsPageStateVar
  }

  if (activePage.includes(TableSortPagesEnum.payments)) {
    variable = paymentsPageStateVar
  }

  if (activePage.match('/inbound')) {
    variable = inboundMandatesStateValue
  }

  if (activePage.match('/outbound')) {
    variable = outboundMandatesStateValue
  }

  if (activePage.match('/scheduled-payments')) {
    variable = directDebitExecutionsStateValue
  }

  if (activePage.includes(TableSortPagesEnum.standingOrders)) {
    variable = standingOrdersPageStateVar
  }

  return variable
}

export const findNumberedObjectKeys = (
  obj: Record<string, unknown>,
  searchName: string,
): string[] => {
  const keys = Object.keys(obj || {})
  const result: string[] = []

  keys.forEach((key) => {
    if (key.startsWith(searchName)) {
      result.push(key)
    }
  })

  return result
}
export const findNumberedObjectValues = (
  obj: Record<string, string>,
  searchName: string,
): string[] => {
  const keys = Object.keys(obj || {})
  const result: string[] = []

  keys.forEach((key) => {
    if (key.startsWith(searchName)) {
      result.push(obj[key])
    }
  })

  return result
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reportAppError = (err: Error, metaData?: any, userId?: string | number) => {
  if (Bugsnag.isStarted()) {
    Bugsnag.notify(err, (e) => {
      Object.keys(metaData || {}).forEach((key) => {
        e.addMetadata(key, metaData[key])
      })
      if (userId) {
        e.setUser(String(userId))
      }
    })
  }
  if (import.meta.env.MODE !== 'production') {
    // eslint-disable-next-line no-console
    console.log('Reported Error => ', { err, metaData, userEmail: userId })
  }
}

export function parsePreassessementValues(
  formData: PreAssessmentOnboardingInput | ManagementBusinessDetailsInput,
  correspondenceNeeded: boolean,
  isIntroducer?: boolean,
) {
  const currencyAndVolumeArray: string[] = []
  const currenciesArray: string[] = []

  formData?.expectedVolumes?.forEach((currVolume: { currency: string; expectedVolume: string }) => {
    if (!isEmpty(currVolume.currency) && !isEmpty(currVolume.expectedVolume)) {
      const { currency, expectedVolume } = currVolume
      currencyAndVolumeArray.push(`${expectedVolume}% ${currency.toUpperCase()}`)
      currenciesArray.push(currency.toLowerCase())
    }
  })

  const cardPayAmpArr = []
  const regionsArr: {
    businessCategoryRegion: BusinessRegion
    businessCategoryPercentage: string
  }[] = formData?.businessRegions ?? []

  for (const region of regionsArr) {
    cardPayAmpArr.push({
      businessCategoryRegion: region.businessCategoryRegion,
      businessCategoryPercentage: Number(region.businessCategoryPercentage),
    })
  }

  const apmCurrArr = []
  const apmCurr: [] = formData?.apmCurrently ?? []
  for (let i = 0; i < apmCurr.length; i++) {
    if (!formData['apmCurrentlyInp1' + i]) {
      break
    }
    apmCurrArr.push({
      ampInUseTypeCurrentlyInUse: formData['apmCurrentlyInp1' + i],
      monthlyVolumeInEuro: `${formData['apmCurrentlyInp2' + i]}`,
    })
  }

  const apmReqArr = []
  const apmReq: [] = formData?.apmRequired ?? []
  for (let i = 0; i < apmReq.length; i++) {
    if (!formData['apmRequiredInp1' + i]) {
      break
    }
    apmReqArr.push({
      ampInUseTypeCurrentlyInUse: formData['apmRequiredInp1' + i],
      monthlyVolumeInEuro: `${formData['apmRequiredInp2' + i]}`,
    })
  }

  const fileInfo: FilesForSavingAndShowType = formData?.historyStatement?.[0]
  const preassessmentDocument = {
    type: DocumentType.PreassessmentHistoryStatementOfCurrentFormerProcessor,
    id: fileInfo?.id,
    firstSide: {
      fileName: fileInfo?.fileName,
      plainUrl: fileInfo?.plainUrl ?? '',
      size: fileInfo?.size,
    },
  }
  const cardPayment: CardPaymentsAndAmp = {
    isCardPayment: formData?.products?.includes(Products.PaymentProcessing),
    cardPaymentsTransactionalDataRegion: isEmpty(cardPayAmpArr) ? null : cardPayAmpArr,
    transactionalCurrencies: formData?.trxCurrencies || [],
    requiredSettlementCurrencies: formData?.settlementCurrencies ?? null,
    totalPredictedMonthlyVolumesVisa: formData?.volumesVisa
      ? mapValues(formData?.volumesVisa, toNumber)
      : null,
    totalPredictedMonthlyVolumesMasterCard: formData?.volumesMaster
      ? mapValues(formData?.volumesMaster, toNumber)
      : null,
    ampCurrentlyInUse: isEmpty(apmCurrArr) ? undefined : apmCurrArr,
    ampRequiredCurrentlyInUse: isEmpty(apmReqArr) ? undefined : apmReqArr,
    riskManagement: {
      useAffiliateForGeneratingTraffic: formData?.selAffiliatesTraffic === YesNoOptions[0].key,
      affiliatesUsed: formData?.affiliatesList ?? null,
      useCredentialOnFilesBillingSalesMethod:
        formData?.selCredentialBillingMethod === YesNoOptions[0].key,
      relevantCredentialOnFilesBillingSalesMethod: formData?.credentialDetails ?? null,
      credentialDetailsExplanation: formData?.credentialDetailsExplanation ?? null,
    },
    pciDssCompliance: {
      integrationTypeRequired: formData?.integrationTypeSelect ?? null,
      dssCompliance: formData?.pciCompliance ?? null,
      pciProviderName: formData?.pciProvidersName ?? null,
    },
    processingHistory: {
      processPaymentCardsBefore: formData?.processedHistory === YesNoOptions[0].key,
      nameOfCurrentFormerProcessor: formData?.currentProcessor ?? null,
      reasonForLeavingCurrentProcessor: formData?.reasonLeavingProcessor ?? null,
      periodOfTimeWithCurrentFormerProcessor: formData?.periodWithProcessor ?? null,
      processingHistoryStatement: !isEmpty(formData?.historyStatement)
        ? preassessmentDocument
        : undefined,
    },
    merchantWebsites: parseWebsitesFormData(formData?.merchantWebsites),
  }

  const input: BankableEntityContractInputType = {
    companyType: CompanyType.LimitedLiabilityCompany,
    legalEntityType: formData?.entityType as UboLegalEntityType,
    companyName: formData?.companyName,
    tradingName: formData?.tradingName,
    companyRegistrationId: formData?.registryNumber,
    taxId: formData?.taxId,
    companyRegistrationDate: formData?.registrationDate,
    phone: formData?.phoneNumber,
    website: parseWebsitesFormData(formData?.urls),
    address: {
      line1: formData?.streetAddress,
      city: formData?.city,
      zip: formData?.postalCode,
      country: formData?.country,
      additionalDetails: formData?.additionalDetailsOptional,
    },
    correspondenceAddress: {
      line1: correspondenceNeeded ? formData?.streetAddressCorrespondence : formData?.streetAddress,
      city: correspondenceNeeded ? formData?.cityCorrespondence : formData?.city,
      zip: correspondenceNeeded ? formData?.postalCodeCorrespondence : formData?.postalCode,
      country: correspondenceNeeded ? formData?.countryCorrespondence : formData?.country,
      additionalDetails: correspondenceNeeded
        ? formData?.additionalDetailsOptionalCorrespondence
        : formData?.additionalDetailsOptional,
    },
    businessSector: formData?.businessSector,
    economicProfile: {
      sourceOfFunds: formData?.sourceOfFunds?.filter((itemData: string) =>
        find(
          economicProfileCompanySourceFundsTypesList,
          (itemCurrentValue) => itemCurrentValue.key === itemData,
        ),
      ),
      sourceOfFundsDescription:
        formData?.sourceOfFunds?.filter(
          (itemData: string) =>
            !find(
              economicProfileCompanySourceFundsTypesList,
              (itemCurrentValue) => itemCurrentValue.key === itemData,
            ),
        )[0] ?? 'N/A',
      inboundSources: uniq(
        flatMap(formData?.partnersIncoming, 'paymentPurpose'),
      ).filter((itemData: string) =>
        find(
          economicProfileTransferDescriptionTypesList,
          (itemCurrentValue) => itemCurrentValue.key === itemData,
        ),
      ),
      inboundSourcesOther:
        uniq(flatMap(formData?.partnersIncoming, 'paymentPurpose')).filter(
          (itemData: string) =>
            !find(
              economicProfileTransferDescriptionTypesList,
              (itemCurrentValue) => itemCurrentValue.key === itemData,
            ),
        )[0] ?? 'N/A',
      anticipatedAccountActivity: formData?.anticipatedAccountActivity,
      products: formData?.products,
      outboundDestinations: uniq(
        flatMap(formData?.partnersOutgoing, 'paymentPurpose'),
      ).filter((itemData: string) =>
        find(
          economicProfileTransferPayrollTypesList,
          (itemCurrentValue) => itemCurrentValue.key === itemData,
        ),
      ),
      outboundDestinationsOther:
        uniq(flatMap(formData?.partnersOutgoing, 'paymentPurpose')).filter(
          (itemData: string) =>
            !find(
              economicProfileTransferPayrollTypesList,
              (itemCurrentValue) => itemCurrentValue.key === itemData,
            ),
        )[0] ?? 'N/A',
      estimateInbound: +formData?.estimatedIncomingTransactionsAmount,
      estimateOutbound: +formData?.estimatedOutgoingTransactionsAmount,
      currencies: currenciesArray,
    },
    businessDescription: formData?.businessActivitiesAndServices,
    countriesOfOperatingAndConductingBusiness: formData?.countriesOfOperatingAndConductingBusiness,
    ...(isIntroducer ? { isIntroducer } : {}),
    preAssessment: {
      ...(cardPayment.isCardPayment ? { cardPayment } : { cardPayment: { isCardPayment: false } }),
      licenseRequired: formData?.licenseRequired,
      licenseCountry: formData?.licenseRequired ? formData?.country : undefined,
      regulatoryInfoName: formData?.licenseRequired ? formData?.regulatorNameAndWebsite : '',
      regulatoryInfoLicenseNumber: formData?.licenseRequired ? formData?.licenseNumber : '',
      paymentProviders: formData?.paymentProviders,
      conductingKYC: formData?.businessSectorConductingKYC || undefined,
      conductingClientKYC: formData?.businessSectorConductingClientKYC || undefined,
      conductPublisherContentMonitoring:
        formData?.businessSectorConductPublisherContentMonitoring || undefined,
      clientsYouWorkWith: formData?.businessSectorClientsYouWorkWith || undefined,
      companyAnnualIncome: formData?.companyAnnualIncome
        ? `${formData.companyAnnualIncome} ${formData?.companyAnnualIncomeCurrency}`
        : undefined,
      incomingMonthlyTurnover: formData?.estimatedIncomingTransactions,
      outgoingMonthlyTurnover: formData?.estimatedOutgoingTransactions,
      currencyAndVolume: currencyAndVolumeArray,
      contactPersonFullName: formData?.fullName || undefined,
      contactPersonEmail: formData?.email || undefined,
      contactPersonPhoneNumber: formData?.contactPersonPhoneNumber || undefined,
      countryOfTaxResidence: formData?.countryOfTaxResidence || undefined,
      uboResidencies: formData?.uboResidencies?.map((r) => r.countries),
      partnersIncoming: formData?.partnersIncoming?.map((p) => ({
        name: p.name,
        country: p.country,
        bankCountry: p.bankCountry,
        businessSector: p.businessSector,
        paymentPurpose: p.paymentPurpose?.filter((itemData: string) =>
          find(
            economicProfileTransferDescriptionTypesList,
            (itemCurrentValue) => itemCurrentValue.key === itemData,
          ),
        ),
        paymentPurposeOther:
          p.paymentPurpose?.filter(
            (itemData: string) =>
              !find(
                economicProfileTransferDescriptionTypesList,
                (itemCurrentValue) => itemCurrentValue.key === itemData,
              ),
          )[0] ?? 'N/A',
        website: p.website,
      })),
      partnersOutgoing: formData?.partnersOutgoing?.map((p) => ({
        name: p.name,
        country: p.country,
        bankCountry: p.bankCountry,
        businessSector: p.businessSector,
        paymentPurpose: p.paymentPurpose?.filter((itemData: string) =>
          find(
            economicProfileTransferPayrollTypesList,
            (itemCurrentValue) => itemCurrentValue.key === itemData,
          ),
        ),
        paymentPurposeOther:
          p.paymentPurpose?.filter(
            (itemData: string) =>
              !find(
                economicProfileTransferPayrollTypesList,
                (itemCurrentValue) => itemCurrentValue.key === itemData,
              ),
          )[0] ?? 'N/A',
        website: p.website,
      })),
      noPartnersIncomingRequired: formData?.noPartnersIncomingRequired,
      noPartnersOutgoingRequired: formData?.noPartnersOutgoingRequired,
      businessRelationshipWithCompany: formData?.businessRelationshipWithCompany || undefined,
      businessRelationshipWithCompanyExplanation:
        formData?.businessRelationshipWithCompanyExplanation || undefined,
    },
  }

  return input
}

export const sortByBooleanProperty = <T, K extends keyof T>(arr: T[], prop: K) => {
  arr.sort((a, b) => {
    if (a[prop] === false && b[prop] === true) {
      return -1
    } else if (a[prop] === true && b[prop] === false) {
      return 1
    }
    return 0
  })
}

// eslint-disable-next-line
export function createInitValues(initialTempValues: { [p: string]: any }) {
  return {
    entityType: initialTempValues.entityType || '',
    companyName: initialTempValues.companyName || '',
    tradingName: initialTempValues.tradingName || '',
    country: initialTempValues.country || '',
    registryNumber: initialTempValues.registryNumber || '',
    registrationDate: initialTempValues.registrationDate || '',
    streetAddress: initialTempValues.streetAddress || '',
    postalCode: initialTempValues.postalCode || '',
    city: initialTempValues.city || '',
    additionalDetailsOptional: initialTempValues.additionalDetailsOptional || '',
    phoneNumber: initialTempValues.phoneNumber || '',
    countryCorrespondence: initialTempValues.countryCorrespondence || '',
    streetAddressCorrespondence: initialTempValues.streetAddressCorrespondence || '',
    postalCodeCorrespondence: initialTempValues.postalCodeCorrespondence || '',
    cityCorrespondence: initialTempValues.cityCorrespondence || '',
    additionalDetailsOptionalCorrespondence:
      initialTempValues.additionalDetailsOptionalCorrespondence || '',
    urls: initialTempValues.urls || [],
    businessSector: initialTempValues.businessSector || '',
    businessActivitiesAndServices: initialTempValues.businessActivitiesAndServices || '',
    taxId: initialTempValues.taxId || '',
    countryOfTaxResidence: initialTempValues.countryOfTaxResidence,
    sourceOfFunds: initialTempValues.sourceOfFunds,
    currenciesProfile: initialTempValues.currenciesProfile || '',
    averageIncomingTransactionsAmount: initialTempValues.averageIncomingTransactionsAmount || '',
    averageOutgoingTransactionsAmount: initialTempValues.averageOutgoingTransactionsAmount || '',
    inboundTransfersDescription: initialTempValues.inboundTransfersDescription || '',
    outboundTransfersDescription: initialTempValues.outboundTransfersDescription || '',
    anticipatedAccountActivity: initialTempValues.anticipatedAccountActivity || '',
    products: initialTempValues.products || '',
    licenseRequired: initialTempValues.licenseRequired || '',
    regulatorNameAndWebsite: initialTempValues.regulatorNameAndWebsite || '',
    licenseNumber: initialTempValues.licenseNumber || '',
    paymentProviders: initialTempValues.paymentProviders || '',
    fullName: initialTempValues.fullName || '',
    email: initialTempValues.email || '',
    contactPersonPhoneNumber: initialTempValues.contactPersonPhoneNumber || '',
    companyAnnualIncome: initialTempValues.companyAnnualIncome || '',
    companyAnnualIncomeCurrency: initialTempValues.companyAnnualIncomeCurrency || '',
    estimatedIncomingTransactions: initialTempValues.estimatedIncomingTransactions || '',
    estimatedOutgoingTransactions: initialTempValues.estimatedOutgoingTransactions || '',
    businessSectorConductingKYC: initialTempValues.businessSectorConductingKYC || '',
    businessSectorConductingClientKYC: initialTempValues.businessSectorConductingClientKYC || '',
    businessSectorConductPublisherContentMonitoring:
      initialTempValues.businessSectorConductPublisherContentMonitoring || '',
    businessSectorClientsYouWorkWith: initialTempValues.businessSectorClientsYouWorkWith || '',
    uboResidencies: initialTempValues.uboResidencies || [],
    partnersIncoming: initialTempValues.partnersIncoming || [],
    partnersOutgoing: initialTempValues.partnersOutgoing || [],
    expectedVolume0: initialTempValues.expectedVolume0 || '',
    expectedCurrency0: initialTempValues.expectedCurrency0 || '',
    expectedVolume1: initialTempValues.expectedVolume1 || '',
    expectedCurrency1: initialTempValues.expectedCurrency1 || '',
    expectedVolume2: initialTempValues.expectedVolume2 || '',
    expectedCurrency2: initialTempValues.expectedCurrency2 || '',
    expectedVolume3: initialTempValues.expectedVolume3 || '',
    expectedCurrency3: initialTempValues.expectedCurrency3 || '',
  }
}

const preparePreAssessmentPartnerData = (arr: unknown) => {
  if (!Array.isArray(arr)) return []

  return arr.map((partnerItem) => {
    const result = { ...partnerItem }
    if (partnerItem?.paymentPurposeOther && partnerItem?.paymentPurposeOther !== 'N/A') {
      result.paymentPurpose = [...(result.paymentPurpose || []), partnerItem.paymentPurposeOther]
    }
    return result
  })
}

const getCurrencyAndVolumes = (currVolumes: Maybe<Maybe<string>[]> | undefined) => {
  const currenciesVolumes: { [key: string]: string } = {}
  const expectedVolumes: ExpectedVolumes[] = []
  const volumesLength = currVolumes?.length ?? 0

  for (let j = 0; j < volumesLength; j++) {
    const currVolume = (currVolumes ?? [])[j]

    if (!isEmpty(currVolume)) {
      const volume = currVolume?.split(' ')[0].split('%')[0]
      const currency = currVolume?.split(' ')[1] as NewAccountCurrency
      currenciesVolumes['expectedVolume' + j] = volume as string
      currenciesVolumes['expectedCurrency' + j] = currency
      volume &&
        currency &&
        expectedVolumes.push({
          currency,
          expectedVolume: volume,
        })
    }
  }

  if (!expectedVolumes.length) {
    expectedVolumes.push(EMPTY_CURRENCY_VOLUME)
  }
  return { currenciesVolumes, expectedVolumes }
}

export function parsePreAssessmentFormData(
  contractData: GetContractDetailsQuery | undefined,
  // eslint-disable-next-line
): [any, boolean] {
  const {
    legalEntityType,
    companyName,
    tradingName,
    address,
    correspondenceAddress,
    companyRegistrationId,
    companyRegistrationDate,
    phone,
    websites,
    website,
    businessSector,
    businessDescription,
    countriesOfOperatingAndConductingBusiness,
    taxId,
    economicProfile,
  } = contractData?.contract?.owner as LegalEntity

  const preAssessment = contractData?.contract?.preAssessment as PreAssessment

  const cardPayment = contractData?.contract?.preAssessment?.cardPayment
  const annualIncomeArr = preAssessment?.companyAnnualIncome?.split(' ') ?? []

  const uploadedDoc = {
    docType: cardPayment?.processingHistory?.processingHistoryStatement?.type,
    documentName: undefined,
    fileName: cardPayment?.processingHistory?.processingHistoryStatement?.firstSide?.fileName,
    id: cardPayment?.processingHistory?.processingHistoryStatement?.id,
    plainUrl: cardPayment?.processingHistory?.processingHistoryStatement?.firstSide?.url,
    size: cardPayment?.processingHistory?.processingHistoryStatement?.firstSide?.size,
    metaType: cardPayment?.processingHistory?.processingHistoryStatement?.firstSide?.mimeType,
  }

  const registeredAddress = new Map([
    ['city', address?.city],
    ['streetAddress', address?.line1],
    ['postalCode', address?.zip],
    ['additionalDetailsOptional', address?.additionalDetails],
  ])

  const corrAddress = new Map([
    ['cityCorrespondence', correspondenceAddress?.city],
    ['streetAddressCorrespondence', correspondenceAddress?.line1],
    ['postalCodeCorrespondence', correspondenceAddress?.zip],
    ['additionalDetailsOptionalCorrespondence', correspondenceAddress?.additionalDetails],
  ])

  const correspondenceRequired = !isEqual(
    Array.from(corrAddress.values()),
    Array.from(registeredAddress.values()),
  )

  const volumesVisa = omit(cardPayment?.totalPredictedMonthlyVolumesVisa, '__typename')
  const volumesMaster = omit(cardPayment?.totalPredictedMonthlyVolumesMasterCard, '__typename')
  const totalPredictedMonthlyVolumes = calculateTotatVolumes(
    volumesVisa as Record<string, number | null>,
    volumesMaster as Record<string, number | null>,
  )
  const urlList =
    (isNull(websites) ? website?.split(',') : websites?.map((site) => ({ value: site }))) || []
  const urlMerchantsList = isNull(cardPayment?.merchantWebsites)
    ? []
    : cardPayment?.merchantWebsites?.map((site) => ({ value: site }))

  const cardPayments = {
    cardPaymentsApm: economicProfile?.products?.includes(Products.PaymentProcessing)
      ? CardPaymentsSelectOptions.required
      : CardPaymentsSelectOptions.notRequired,
    businessRegions: cardPayment?.cardPaymentsTransactionalDataRegion || [],
    apmCurrently: cardPayment?.ampCurrentlyInUse,
    apmRequired: cardPayment?.ampRequiredCurrentlyInUse,
    integrationTypeSelect: cardPayment?.pciDssCompliance?.integrationTypeRequired?.[0] ?? null,
    pciCompliance: cardPayment?.pciDssCompliance?.dssCompliance?.[0] ?? [],
    pciProvidersName: cardPayment?.pciDssCompliance?.pciProviderName ?? '',
    processedHistory: cardPayment?.processingHistory?.processPaymentCardsBefore
      ? YesNoOptions[0].key
      : YesNoOptions[1].key,
    currentProcessor: cardPayment?.processingHistory?.nameOfCurrentFormerProcessor ?? '',
    reasonLeavingProcessor: cardPayment?.processingHistory?.reasonForLeavingCurrentProcessor ?? '',
    periodWithProcessor:
      cardPayment?.processingHistory?.periodOfTimeWithCurrentFormerProcessor ?? '',
    selAffiliatesTraffic: cardPayment?.riskManagement?.useAffiliateForGeneratingTraffic
      ? YesNoOptions[0].key
      : YesNoOptions[1].key,
    affiliatesList: cardPayment?.riskManagement?.affiliatesUsed,
    selCredentialBillingMethod: cardPayment?.riskManagement?.useCredentialOnFilesBillingSalesMethod
      ? YesNoOptions[0].key
      : YesNoOptions[1].key,
    credentialDetails: cardPayment?.riskManagement?.relevantCredentialOnFilesBillingSalesMethod,
    credentialDetailsExplanation: cardPayment?.riskManagement?.credentialDetailsExplanation,
    trxCurrencies: cardPayment?.transactionalCurrencies || [],
    settlementCurrencies: cardPayment?.requiredSettlementCurrencies || [],
    totalPredictedMonthlyVolumes,
    volumesVisa,
    volumesMaster,
    historyStatement: cardPayment?.processingHistory?.processingHistoryStatement
      ? [uploadedDoc]
      : [],
    merchantWebsites: urlMerchantsList,
  }

  const { currenciesVolumes, expectedVolumes } = getCurrencyAndVolumes(
    preAssessment?.currencyAndVolume,
  )

  const parsedFormData = {
    ...(cardPayment?.isCardPayment ? cardPayments : {}),
    ...currenciesVolumes,
    entityType: legalEntityType,
    companyName,
    tradingName: tradingName === '-' || isNull(tradingName) ? '' : tradingName,
    country: address?.country,
    registryNumber: companyRegistrationId,
    registrationDate: companyRegistrationDate,
    streetAddress: address?.line1,
    postalCode: address?.zip || '',
    city: address?.city || '',
    additionalDetailsOptional: address?.additionalDetails || '',
    phoneNumber: phone,
    countryCorrespondence: correspondenceAddress?.country,
    streetAddressCorrespondence: correspondenceAddress?.line1,
    postalCodeCorrespondence: correspondenceAddress?.zip,
    cityCorrespondence: correspondenceAddress?.city,
    additionalDetailsOptionalCorrespondence: correspondenceAddress?.additionalDetails,
    urls: urlList,
    businessSector: businessSector || '',
    businessActivitiesAndServices: businessDescription || '',
    countriesOfOperatingAndConductingBusiness,
    taxId,
    countryOfTaxResidence: preAssessment?.countryOfTaxResidence?.toLowerCase(),
    sourceOfFunds:
      economicProfile?.sourceOfFundsDescription &&
      economicProfile.sourceOfFundsDescription !== 'N/A'
        ? [...(economicProfile?.sourceOfFunds || []), economicProfile.sourceOfFundsDescription]
        : economicProfile?.sourceOfFunds,
    currenciesProfile: economicProfile?.currencies,
    estimatedIncomingTransactionsAmount: economicProfile?.estimateInbound,
    estimatedOutgoingTransactionsAmount: economicProfile?.estimateOutbound,
    inboundTransfersDescription:
      economicProfile?.inboundSourcesOther && economicProfile?.inboundSourcesOther !== 'N/A'
        ? [...(economicProfile?.inboundSources as string[]), economicProfile?.inboundSourcesOther]
        : economicProfile?.inboundSources,
    outboundTransfersDescription:
      economicProfile?.outboundDestinationsOther &&
      economicProfile?.outboundDestinationsOther !== 'N/A'
        ? [
            ...(economicProfile?.outboundDestinations as string[]),
            economicProfile?.outboundDestinationsOther,
          ]
        : economicProfile?.outboundDestinations,
    anticipatedAccountActivity: economicProfile?.anticipatedAccountActivity,
    products: economicProfile?.products,
    licenseRequired: preAssessment?.licenseRequired,
    regulatorNameAndWebsite: preAssessment?.regulatoryInfoName,
    licenseNumber: preAssessment?.regulatoryInfoLicenseNumber,
    paymentProviders: preAssessment?.paymentProviders,
    fullName: preAssessment?.contactPersonFullName,
    email: preAssessment?.contactPersonEmail,
    contactPersonPhoneNumber: preAssessment?.contactPersonPhoneNumber,
    companyAnnualIncome: annualIncomeArr[0],
    companyAnnualIncomeCurrency: annualIncomeArr[1],
    estimatedIncomingTransactions: preAssessment?.incomingMonthlyTurnover,
    estimatedOutgoingTransactions: preAssessment?.outgoingMonthlyTurnover,
    businessSectorConductingKYC: preAssessment?.conductingKYC,
    businessSectorConductingClientKYC: preAssessment?.conductingClientKYC,
    businessSectorConductPublisherContentMonitoring:
      preAssessment?.conductPublisherContentMonitoring,
    businessSectorClientsYouWorkWith: preAssessment?.clientsYouWorkWith,
    uboResidencies: map(preAssessment?.uboResidencies, (r) => ({ countries: r })),
    partnersIncoming: preparePreAssessmentPartnerData(preAssessment?.partnersIncoming),
    partnersOutgoing: preparePreAssessmentPartnerData(preAssessment?.partnersOutgoing),
    noPartnersIncomingRequired: isNull(preAssessment?.noPartnersIncomingRequired)
      ? false
      : preAssessment?.noPartnersIncomingRequired,
    noPartnersOutgoingRequired: isNull(preAssessment?.noPartnersOutgoingRequired)
      ? false
      : preAssessment?.noPartnersOutgoingRequired,
    businessRelationshipWithCompany: preAssessment?.businessRelationshipWithCompany,
    businessRelationshipWithCompanyExplanation:
      preAssessment?.businessRelationshipWithCompanyExplanation || '',
    expectedVolumes,
  }
  return [parsedFormData, correspondenceRequired]
}

export const getLabelByKey = (
  key: string | null | undefined = '',
  values: BaseEnumWithKey[] | KeyLabelType[],
): string => {
  return values.find((v: BaseEnumWithKey | KeyLabelType) => key === v.key)?.label || ''
}

export const getLabelsSeparatedByCommas = (
  arr: Maybe<string>[],
  values: BaseEnumWithKey[] | KeyAndLabelSelectType[],
): string => {
  const result = arr.map(
    (el) => values.find((v: BaseEnumWithKey | KeyAndLabelSelectType) => el === v.key)?.label,
  )
  return result.join(', ')
}
export const getEnumLabel = (val: string, key: string): string => {
  switch (key) {
    case 'businessSector':
      return getLabelByKey(val, BusinessCategoriesList)
    case 'incomingMonthlyTurnover':
    case 'outgoingMonthlyTurnover':
      return getLabelByKey(val, economicProfileAverageSizeTypesList)
    case 'countryOfTaxResidence':
      return getLabelByKey(val, CountriesList)
    case 'integrationTypeSelect':
      return getLabelByKey(val, IntegrationTypeOptions)
    case 'pciCompliance':
      return getLabelByKey(val, PciComplianceOptions)
    case 'credentialDetails':
      return getLabelByKey(val, credentialDetailsList)
    case 'countriesOfOperatingAndConductingBusiness':
      return getLabelByKey(val, countriesAndRegions)
    default:
      return val
  }
}

export const makeDocTypeValidationMessage = (docType: string): string => {
  const docLabel = find(DocumentTypesList, (o) => o.key === docType)?.label
  return PLEASE_UPLOAD_DOCUMENT_TYPE(docLabel ?? docType)
}

export function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

export function calculateTotatVolumes(
  visaValues: Record<string, number | null>,
  masterValues: Record<string, number | null>,
) {
  if (!visaValues || !masterValues) return 0

  const totalVisa: number = Object.values(visaValues).reduce(
    (acc: number, curr) => acc + toNumber(curr ?? '0'),
    0,
  )
  const totalMaster: number = Object.values(masterValues).reduce(
    (acc: number, curr) => acc + toNumber(curr ?? '0'),
    0,
  )
  return totalVisa + totalMaster
}

export function successFeePayoutStatusNormalize(statusValue: SuccessFeePayoutStatus): string {
  switch (statusValue) {
    case SuccessFeePayoutStatus.Completed:
      return 'received'
    case SuccessFeePayoutStatus.Rejected:
      return 'declined'
    default:
      return 'pending'
  }
}

export const setTabProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
})

export const handleLatinKeysDown = (
  e: React.KeyboardEvent<HTMLDivElement>,
  onlyLatinLetters = true,
) => {
  if (onlyLatinLetters && !ONLY_LATIN_LETTERS_WITH_SPACES_BETWEEN_WORDS.test(e.key)) {
    e.preventDefault()
  }
}

export const checkPassword = (
  e: React.ChangeEvent<HTMLInputElement>,
  setError: (name: string, error: Record<string, string>) => void,
  clearErrors: (name: string) => void,
) => {
  const { value, name } = e.target
  const regexCapital = /[A-Z]/
  const regexDigit = /[0-9]/
  const haveCapitalLater = regexCapital.test(value)
  const haveDigit = regexDigit.test(value)
  const isNotEnoughSymbols = value.length < 8

  let helperText = `Must contains`

  if (isNotEnoughSymbols) {
    helperText = helperText.concat(` ${Math.abs(value.length - 8)} more symbols`)
  }

  if (isNotEnoughSymbols && (!haveDigit || !haveCapitalLater)) {
    helperText = helperText.concat(`${!haveDigit && !haveCapitalLater ? ',' : ' and'}`)
  }

  if (!haveCapitalLater) {
    helperText = helperText.concat(' 1 more capital')
  }
  if (!haveDigit) {
    helperText = helperText.concat(
      ` ${isNotEnoughSymbols || haveCapitalLater ? '' : 'and'} 1 more digit`,
    )
  }

  setError(name, {
    type: 'manual',
    message: helperText,
  })

  if (!isNotEnoughSymbols && haveCapitalLater && haveDigit) {
    clearErrors(name)
  }
}

export function hasLimitations(
  accountsData: GetContractAccountsQuery | undefined,
  cardsData: CardsListQuery | undefined,
  type: ContractType,
  contractCountry?: string,
) {
  const cardsList = cardsData?.contractCards
  const accountsAvailableCurrencies = accountsData?.accounts?.map((account) => account?.currency)
  const allowedCardCurrencies = intersection(
    accountsAvailableCurrencies,
    config.allowedCardCurrencies,
  )

  const hasPreviouslyActivatedCard = cardsList?.some((card) => !!card?.isCard3dSecureActivated)

  // Contract With Activated Cards Can Still Continue Issue Even If Allowed Countries Change
  if (!hasPreviouslyActivatedCard) {
    if (type === ContractType.Individual && contractCountry) {
      return (
        import.meta.env.REACT_APP_INDIVIDUAL_COUNTRY_EUR?.includes(contractCountry) &&
        allowedCardCurrencies.includes(Currency.Eur)
      )
    }

    if (type === ContractType.Corporate && contractCountry) {
      return (
        (import.meta.env.REACT_APP_CORPORATE_COUNTRY_EUR?.includes(contractCountry) &&
          allowedCardCurrencies.includes(Currency.Eur)) ||
        (import.meta.env.REACT_APP_CORPORATE_COUNTRY_USD?.includes(contractCountry) &&
          allowedCardCurrencies.includes(Currency.Usd))
      )
    }
  }
  return !isEmpty(allowedCardCurrencies)
}

export const amountTransferToNumber = (fieldAmountTransfer: number | null | undefined) => {
  if (Number.isNaN(fieldAmountTransfer)) return 0
  return !!Number(fieldAmountTransfer) || fieldAmountTransfer === 0
    ? Number(fieldAmountTransfer)
    : Number(fieldAmountTransfer?.toString()?.replaceAll(',', ''))
}

export const isKlarpayAccount = (iban: string) => {
  try {
    if (!iban || !IbanKit.IBAN.isValid(iban)) return false

    const bankSortCode = import.meta.env.REACT_APP_KLARPAY_ACCOUNT_SORTCODE
    const bankSortCodeQrr = import.meta.env.REACT_APP_KLARPAY_ACCOUNT_QR_SORTCODE

    const normalizedIBAN = IbanKit.IBAN.electronicFormat(iban)
    const formattedIban = new IbanKit.IBAN(normalizedIBAN)
    const branchCode = formattedIban.getBranchCode()
    const countryCode = formattedIban.getCountryCode()
    let bankCode = formattedIban.getBankCode()
    if (countryCode === 'PL') {
      bankCode = `${bankCode}${branchCode}${formattedIban.getNationalCheckDigit()}`
    }
    const klarpaySortCodes = [bankSortCode, bankSortCodeQrr]

    return Boolean(countryCode === 'CH' && !!bankCode && klarpaySortCodes.includes(bankCode))
  } catch {
    return false
  }
}

export function getCryptoTransactionSteps(
  trxRequest: Maybe<TransactionRequest> | undefined,
): StepType[] {
  const isFailed = [TransactionRequestStatus.Cancelled, TransactionRequestStatus.Rejected].includes(
    trxRequest?.status as TransactionRequestStatus,
  )
  return [
    {
      pendingLabel: 'transferCreated',
      label: 'transferCreated',
      isCurrentStep: (status: TransactionRequestStatus) =>
        status === TransactionRequestStatus.Pending,
    },
    ...(isFailed
      ? [
          {
            pendingLabel: 'transferDeclined',
            label: 'transferDeclined',
            isCurrentStep: () => false,
          },
        ]
      : [
          ...(trxRequest?.status === TransactionRequestStatus.PendingAml
            ? [
                {
                  pendingLabel: 'waitingForAddDocumentation',
                  label: 'waitingForAddDocumentation',
                  isCurrentStep: (status: TransactionRequestStatus) =>
                    status === TransactionRequestStatus.PendingAml,
                },
              ]
            : []),
          {
            pendingLabel: 'transferReadyForExecution',
            label: 'transferExecuted',
            isCurrentStep: (status: TransactionRequestStatus) =>
              status === TransactionRequestStatus.ReadyForSending,
          },
          {
            pendingLabel: 'cryptoWillBeReceivedByExchange',
            label: 'cryptoReceivedByExchange',
            description: i18n.t(
              'cryptoWillBeReceivedByExchangeExpl',
              'This can take several minutes depending on the blockchain network.',
            ),
            isCurrentStep: (status: TransactionRequestStatus) =>
              status === TransactionRequestStatus.Processing,
          },
        ]),
  ]
}

export const getTranslateFooterName = (t: TFunction) => {
  const translatedNameKey = config.isWhitelabel ? 'poweredByKlarpayAG' : 'klarpayAG'
  const translatedNameDefault = config.isWhitelabel ? 'Powered By Bivial AG' : 'Bivial AG'
  return t(translatedNameKey, translatedNameDefault)
}

export const parseLocalizedNumber = (value: string | number): number => {
  if (typeof value === 'number') return value

  return parseFloat(value.replace(/,/g, ''))
}

export function moderateWalletTrxParams(
  filtersState: WalletTrxPageStateType,
  queryParams: GetWalletTransactionsQueryVariables,
): GetWalletTransactionsQueryVariables {
  const updatedParams = { ...queryParams }

  if (filtersState.status) {
    switch (filtersState.status) {
      case 'allStatuses':
        delete updatedParams.status
        break
      default:
        delete updatedParams.status
    }
  }

  return updatedParams
}
