import React, { FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Fade, Grid, makeStyles, Typography } from '@material-ui/core'
import { generatePath, useLocation, useParams } from 'react-router-dom'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'

import { useStyles } from './Tabs/useStyles'
import {
  CreateOwnDocumentRelatedMutationVariables,
  Document,
  Maybe,
  SwiftTransferRecipientType,
} from '../../graphql'
import BatchIcon from '../../assets/images/icons/upload_up.svg?react'
import UploadIcon from '../../assets/images/icons/paymentIcon.svg?react'
import { ContractChanges, FilesForSavingAndShowType } from '../../types'
import { DocsUploadModal } from './DocsUploadModal'
import { DocsUploadModalTransactions } from './DocsUploadModalTransactions'
import { UploadConfirmationModal } from '../../components'
import FileList from './FileList'
import { DocsUploadModalWallets } from './DocsUploadModalWallets'
import clsx from 'clsx'

const uploadStyles = ({
  isDetailsPage,
  isContractManagementPage,
  isManagementPage,
  isWalletPage,
}: Record<string, boolean>) =>
  makeStyles((theme) => ({
    root: {
      margin: isContractManagementPage
        ? 0
        : isDetailsPage
        ? theme.spacing(4, 0, 1, 0)
        : theme.spacing(4, 0, 3, 0),
    },
    rootWrap: {
      maxWidth: 650,
    },
    rootWrapEmpty: {
      maxWidth: 315,
    },
    btn: {
      padding: theme.spacing(0.5, 2.25),
      minHeight: 48,
      left: 0,
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    btnTrx: {
      padding: theme.spacing(0.4, 0.1),
      minHeight: 'auto',
      //margin: theme.spacing(0, 1),
      '& svg path:first-child': {
        fill: 'transparent',
      },
      '&:hover': {
        color: '#ef2828',
        border: '1px solid #ef2828',
        backgroundColor: 'transparent',
        '& svg path:first-child': {
          fill: 'transparent',
        },
        '& svg path:last-child': {
          fill: '#EF2828',
        },
      },
      '& .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '104px',
        height: 'auto',
      },
    },
    imgUpload: {
      width: '30px',
      height: '30px',
      transform: 'translate(-10%, -15%)',
    },
    disabled: {
      opacity: 0.4,
    },
    buttonText: {
      fontSize: '14px',
      fontWeight: 'bold',
      paddingRight: '12px',
    },
    btnIcon: {
      marginLeft: theme.spacing(1),
    },
    wrapLabel: {
      alignSelf: 'center',
      padding: theme.spacing(0, 2),
    },
    uploadButtonBlock: {
      display: 'flex',
      flex: 1,
      justifyContent: 'end',
    },
    docsPanel: {
      display: 'flex',
      justifyContent: isDetailsPage ? 'start' : 'end',
      margin: isManagementPage || !isWalletPage ? 0 : '0 0 28px 0',
    },
  }))()

export const UploadDocument: FC<{
  accountId?: string | number | undefined
  trxId?: string | number | undefined
  relatedStandingOrderId?: string | number | undefined
  walletId?: string | undefined
  transactionRequestId?: string | number | undefined
  relatedIndividualIncluded?: boolean
  isTransaction?: boolean
  isDisabled?: boolean
  isPaymentAboveCertainAmount?: boolean
  setPaymentReviewDocs?: React.Dispatch<
    React.SetStateAction<CreateOwnDocumentRelatedMutationVariables[] | undefined>
  >
  paymentReviewDocs?: CreateOwnDocumentRelatedMutationVariables[] | undefined
  setFilesForSaving?: React.Dispatch<SetStateAction<FilesForSavingAndShowType[] | undefined>>
  filesForSaving?: FilesForSavingAndShowType[] | undefined
  paymentReviewTrx?: string
  recipientType?: Maybe<SwiftTransferRecipientType> | undefined
  contractChanges?: ContractChanges[]
  contractChangesId?: number
  refetchDataCallback?: () => void
  isApproved?: boolean
}> = ({
  accountId,
  trxId,
  relatedStandingOrderId,
  walletId,
  transactionRequestId,
  relatedIndividualIncluded = false,
  isTransaction = false,
  isDisabled = false,
  setPaymentReviewDocs,
  paymentReviewDocs,
  setFilesForSaving,
  filesForSaving,
  paymentReviewTrx,
  recipientType,
  contractChanges,
  contractChangesId,
  isPaymentAboveCertainAmount,
  refetchDataCallback,
  isApproved = false,
}) => {
  const { t } = useTranslation()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const location = useLocation()

  const isDetailsPage = isTransaction || !!contractChangesId || !!transactionRequestId
  const isContractManagementPage = relatedIndividualIncluded
  const isWalletPage = !!walletId
  const isManagementPage =
    location.pathname ===
    generatePath(APP_PATHS.dashboard.contractManagement.root, {
      [PATH_PARAMS.applicationId]: applicationId,
      [PATH_PARAMS.contractManagementTab]: 0,
    })

  const classes = uploadStyles({
    isDetailsPage,
    isContractManagementPage,
    isManagementPage,
    isWalletPage,
  })
  const tabsClasses = useStyles()
  const [open, setOpen] = useState(false)
  const [supportingDocuments, setSupportingDocuments] = useState<Document[]>()

  const onUpload = useCallback(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    if (isPaymentAboveCertainAmount) {
      onUpload()
    }
  }, [isPaymentAboveCertainAmount])

  const handleCloseModal = useCallback(() => {
    setOpen(false)
  }, [])

  const uploadDocumentsModal =
    isTransaction && !isPaymentAboveCertainAmount ? (
      <DocsUploadModalTransactions
        open={open}
        setOpen={setOpen}
        setSupportingDocuments={setSupportingDocuments}
        setPaymentReviewDocs={setPaymentReviewDocs}
        paymentReviewDocs={paymentReviewDocs}
        setFilesForSaving={setFilesForSaving}
        filesForSaving={filesForSaving}
        accountId={accountId}
        trxId={trxId}
        relatedStandingOrderId={relatedStandingOrderId}
      />
    ) : isWalletPage ? (
      <DocsUploadModalWallets
        walletId={walletId}
        transactionRequestId={transactionRequestId}
        open={open}
        setOpen={setOpen}
        setFilesForSaving={setFilesForSaving}
        filesForSaving={filesForSaving}
        setSupportingDocuments={transactionRequestId ? setSupportingDocuments : undefined}
      />
    ) : (
      <DocsUploadModal
        open={open}
        setOpen={setOpen}
        setSupportingDocuments={setSupportingDocuments}
        setPaymentReviewDocs={setPaymentReviewDocs}
        paymentReviewDocs={paymentReviewDocs}
        setFilesForSaving={setFilesForSaving}
        filesForSaving={filesForSaving}
        relatedIndividualIncluded={relatedIndividualIncluded}
        accountId={accountId}
        trxId={trxId}
        recipientType={recipientType}
        paymentReviewTrx={paymentReviewTrx}
        isPaymentAboveCertainAmount={isPaymentAboveCertainAmount}
        refetchDataCallback={refetchDataCallback}
      />
    )

  return (
    <Box className={classes.root}>
      <Box
        className={`${isDetailsPage ? classes.rootWrap : ''} ${
          supportingDocuments?.length || !isDetailsPage || !!paymentReviewDocs
            ? ''
            : classes.rootWrapEmpty
        }`}
      >
        {(!isApproved || !!supportingDocuments?.length) && (
          <Grid container spacing={0} className={classes.docsPanel}>
            {isDetailsPage && (
              <Grid className={classes.wrapLabel}>
                <Typography variant="h4" className={tabsClasses.label}>
                  {t('relatedDocuments', 'Related documents')}
                </Typography>
              </Grid>
            )}
            <Fade in={!isApproved}>
              <Grid item className={classes.uploadButtonBlock}>
                {isDetailsPage ? (
                  <Button
                    variant="outlined"
                    disableElevation
                    color="primary"
                    onClick={onUpload}
                    className={classes.btnTrx}
                    disabled={isDisabled}
                  >
                    <UploadIcon
                      className={clsx(classes.imgUpload, isDisabled && classes.disabled)}
                    />
                    <Typography className={classes.buttonText} variant="body1" component="span">
                      {t('upload', 'Upload')}
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    className={classes.btn}
                    variant="contained"
                    disableElevation
                    color="default"
                    onClick={onUpload}
                    data-test="uploadNewDocument"
                  >
                    {t('uploadNewDocument', 'Upload new document')}
                    <BatchIcon style={{ fill: 'black' }} className={classes.btnIcon} />
                  </Button>
                )}
              </Grid>
            </Fade>
          </Grid>
        )}
        {!!contractChangesId ? (
          <UploadConfirmationModal
            isOpen={open}
            closeModal={handleCloseModal}
            contractChanges={contractChanges}
            contractChangesId={contractChangesId}
            setSupportingDocuments={setSupportingDocuments}
          />
        ) : (
          uploadDocumentsModal
        )}
        <FileList documents={supportingDocuments} />
      </Box>
    </Box>
  )
}
