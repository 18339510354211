import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Grow from '@material-ui/core/Grow'
import { FormProvider, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Button, FormControl, Grid, IconButton, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { InputAdornment } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import { every, isEmpty } from 'lodash'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import { FormControlledTextField, GridRow, LinkButton } from '../../components'
import { EMPTY_CURRENCY_VOLUME } from '../../constants'
import { currencies } from '../../stubs'
import { currenciesForSelectFunc } from '../../utils'
import { ContractProfileChangeAnswers } from '../../graphql'
import {
  makeTooltipTitleTextExpectedVolumes,
  useCommonStyles,
} from '../../components/NewBusinessApp/PreAssessmentQuestionnaire/helpers/functions'
import ControlledTooltipWrapped from '../../components/Common/Tooltips/ControlledTooltipWrapped'
import FormAutocompleteSelectNew from '../../components/Common/Selectors/FormAutocompleteSelectNew'
import { ExpectedVolumes, ExpectedVolumesErrorTypes } from '../../types'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(0.1),
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  twoRows: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(5),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: 0,
    },
  },
  volume: {
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
      paddingRight: 15,
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        transform: 'translate(0, 6px)',
      },
    },
    '& .MuiInputBase-input': {
      paddingRight: 5,
      '-moz-appearance': 'textfield',
      [theme.breakpoints.down('xs')]: {
        transform: 'translate(0, 6px)',
      },
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputLabel-root': {
      whiteSpace: 'nowrap',
    },
  },
  addButton: {
    width: 132,
    margin: theme.spacing(0.5, 0, 1.5, 1),
  },
  redError: {
    color: '#EF2828',
    position: 'initial',
    marginBottom: theme.spacing(1.5),
  },
  errorMessage: {
    display: 'flex',
    '& p': {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: '20%',
        width: '100%',
        textAlign: 'right',
      },
    },
  },
  buttonsBox: {
    '& .MuiButton-root': {
      minWidth: 10,
      minHeight: 'auto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    '& .MuiButton-label': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('xs')]: {
      color: '#EF2828',
    },
  },
  dirtyControl: {
    position: 'relative',
    height: 10,
    marginTop: 10,
  },
  lightDivider: {
    margin: theme.spacing(1, 0),
    backgroundColor: 'rgba(255, 255, 255,.3)',
    height: 3,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  errorItem: {
    paddingLeft: 15,
  },
  volumeContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}))

export const PreAssessmentExpectedVolumes: FC<{
  readOnly?: boolean
  changedContractProfileData?: ContractProfileChangeAnswers
  isOnboarding?: boolean
}> = ({ readOnly = false, changedContractProfileData, isOnboarding }) => {
  const fieldName = 'expectedVolumes'

  const { t } = useTranslation()
  const classes = { ...useCommonStyles(), ...useStyles() }

  const methods = useFormContext()

  const { setValue, errors, control, formState, trigger } = methods

  const { fields, append, remove } = useFieldArray({ control, name: fieldName })

  const [activeRow, setActiveRow] = useState<number | null>(null)

  const currencyVolumes = useWatch({
    name: fieldName,
    control,
  }) as ExpectedVolumes[]

  const expectedVolumeSumPercent = useMemo(
    () =>
      currencyVolumes
        ? currencyVolumes?.reduce((acc, field) => acc + Number(field.expectedVolume), 0)
        : 0,
    [currencyVolumes],
  )

  const isNoOptions = useMemo(() => Object.keys(currencies).length - fields?.length <= 0, [
    fields,
    currencies,
  ])

  const isNoSelectedCurrency = useMemo(
    () => currencyVolumes?.some((item: Record<string, string>) => !item.currency),
    [currencyVolumes],
  )

  const error = errors ? errors[fieldName] : null

  const onAdd = useCallback(() => {
    append(EMPTY_CURRENCY_VOLUME)
  }, [currencyVolumes, setValue, append])

  const onRemove = useCallback(
    (indexToRemove: number) => {
      remove(indexToRemove)
    },
    [currencyVolumes, setValue, remove],
  )

  const handleRemove = useCallback(
    (index: number) => () => {
      onRemove(index)

      if (fields.length <= 1) {
        onAdd()
      }
    },
    [onAdd, fields, onRemove],
  )

  useEffect(() => {
    if (!currencyVolumes?.length) {
      onAdd()
    } else if (
      !!currencyVolumes?.length &&
      every(
        currencyVolumes,
        (currencyVolume) => currencyVolume.expectedVolume && currencyVolume.currency,
      ) &&
      currencyVolumes[0].currency &&
      currencyVolumes[0].expectedVolume
    ) {
      void trigger(fieldName)
    }
  }, [currencyVolumes])

  const isAddDisabled =
    expectedVolumeSumPercent >= 100 || !!error || isNoOptions || isNoSelectedCurrency

  return (
    <>
      {isOnboarding && (
        <Typography variant="h6" className={classes.descriptionForField}>
          {t(
            'whichCurrenciesWillYouNeedToProcessAndWhatVolume',
            'Which currencies will you need to process and what is the expected volume',
          )}
          ?
        </Typography>
      )}
      <FormProvider {...methods}>
        <Box>
          <GridRow ignore={isOnboarding}>
            {!isEmpty(fields) &&
              fields.map((field, index) => {
                return (
                  <Box key={field.id} className={classes.container}>
                    <Box className={classes.twoRows}>
                      <FormControl className={classes.formControl}>
                        <FormAutocompleteSelectNew
                          className={classes.whiteBackground}
                          label={t('currency', 'Currency')}
                          name={`expectedVolumes[${index}].currency`}
                          data={currenciesForSelectFunc(currencyVolumes, index)}
                          defaultValue={field.currency}
                          readOnly={readOnly}
                          onInputChange={() => trigger(fieldName)}
                          data-test={`expectedVolumesCurrency${index}`}
                        />
                      </FormControl>
                      <Box
                        className={classes.volumeContainer}
                        onMouseEnter={() => setActiveRow(index)}
                        onMouseLeave={() => setActiveRow(null)}
                      >
                        <FormControlledTextField
                          className={clsx(classes.volume, classes.whiteBackground)}
                          label={
                            isOnboarding
                              ? t('volumeShare', 'Volume share')
                              : t('expectedVolume', 'Expected Volume')
                          }
                          name={`expectedVolumes[${index}].expectedVolume`}
                          defaultValue={field.expectedVolume}
                          fullWidth
                          required={false}
                          type="number"
                          inputProps={{
                            step: '1',
                            style: { textAlign: 'right' },
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{'%'}</InputAdornment>,
                          }}
                          disabled={readOnly}
                          onInputChange={() => trigger(fieldName)}
                          data-test={`expectedVolumesVolume${index}`}
                        />
                        {activeRow === index && !readOnly && (
                          <Grow in timeout={500}>
                            <Box className={classes.buttonsBox}>
                              <IconButton
                                color="inherit"
                                aria-label="delete"
                                onClick={handleRemove(index)}
                                data-test={`expectedVolumesDelete${index}`}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Box>
                          </Grow>
                        )}
                      </Box>
                    </Box>
                  </Box>
                )
              })}
            {!!error && (
              <Grid className={classes.errorMessage} data-test="expectedVolume">
                <Box xs={6}>
                  {error.type === ExpectedVolumesErrorTypes.volumeIsNotEmptyCurrency && (
                    <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
                  )}
                </Box>
                <Box xs={6} className={classes.errorItem}>
                  {error.type !== ExpectedVolumesErrorTypes.volumeIsNotEmptyCurrency && (
                    <FormHelperText className={classes.redError}>{error.message}</FormHelperText>
                  )}
                </Box>
              </Grid>
            )}
            {changedContractProfileData?.preAssessment?.currencyAndVolume && (
              <ControlledTooltipWrapped
                wrapperClass={classes.dirtyControl}
                hidden={formState.errors.expectedVolumes}
                title={makeTooltipTitleTextExpectedVolumes(
                  changedContractProfileData?.preAssessment?.currencyAndVolume as string[],
                  { divider: classes.lightDivider, listItem: classes.listItem },
                )}
              />
            )}
            <Box hidden={readOnly}>
              {isOnboarding ? (
                <LinkButton
                  onClick={onAdd}
                  disabled={isAddDisabled}
                  data-test="autotest-addExpectedVolumesCurrency"
                >
                  + {t('addCurrency', 'Add currency')}
                </LinkButton>
              ) : (
                <Grid item className={classes.addButton}>
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    disableElevation
                    onClick={onAdd}
                    disabled={isAddDisabled}
                  >
                    {t('add', 'Add')}
                  </Button>
                </Grid>
              )}
            </Box>
          </GridRow>
        </Box>
      </FormProvider>
    </>
  )
}

export default PreAssessmentExpectedVolumes
