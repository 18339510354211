import {
  anticipatedAccountActivities,
  documentTypes,
  economicProfileAccountBalance,
  economicProfileAverageSizeTypes,
  economicProfileEmploymentStatus,
  economicProfileProducts,
  economicProfileSourceFundsTypes,
  economicProfileTransactionSourceDestinationTypes,
} from '@klarpay/enums'
import { find, some, startCase } from 'lodash'
import {
  AccountAction,
  Action,
  ActionSignature,
  ActionSignatureStatus,
  ActionType,
  AddContractAuthorizedPersonRequestAction,
  Address,
  AllCurrencies,
  BatchPaymentActionType,
  BatchPaymentStatusType,
  Coin,
  Currency,
  DebitTransaction,
  DirectDebitExecution,
  DocumentAction,
  Maybe,
  Nationality,
  RefundPaymentRequestAction,
  SpendingLimitRequestAction,
  SupportedCryptoNetwork,
  SwiftTransferChargeBearer,
  SwiftTransferRecipientType,
  Transaction,
  TransactionAction,
  TransactionExecutionType,
  TransactionPriorityType,
  TransactionRequest,
  TransactionRequestStatus,
  TransactionStatus,
} from '../../graphql'
import i18n from '../../i18n'
import { ActionsFilterTypesEnum, TransactionType } from '../../types'
import { currencyFormat, transformActionStatus } from '../../utils'

export type SignatureProps = {
  id: string | number
  personId?: number
  signatory: string
  signatoryRight?: string
  status: ActionSignatureStatus
  actionTaken?: string
}

export type ActionSignatureProps = {
  rawData:
    | ActionSignature
    | Action
    | Transaction
    | SpendingLimitRequestAction
    | TransactionRequest
    | undefined
  signatures: SignatureProps[] | undefined
}

export type CardTrxData = {
  title?: string
  status?: Maybe<TransactionStatus>
  type?: TransactionType
  referenceNumber?: Maybe<string | number> | undefined
  amount?: string
  currency?: string
  outboundTransaction?: string | number
  beneficiary?: string
  address?: Maybe<Address>
  vendor?: string
  sent?: string
  accountName?: Maybe<string> | undefined
  cardholder?: Maybe<string> | undefined
  cardName: Maybe<string> | undefined
  cardNumber: Maybe<string> | undefined
}

export type TransactionData = {
  title?: string
  status?: Maybe<TransactionStatus>
  referenceNumber?: Maybe<string | number> | undefined
  amount?: string
  payoutAmount?: string
  payoutCurrency?: string
  accountCurrency?: string
  chargeBearer?: SwiftTransferChargeBearer
  currency?: string
  sender?: string
  received?: string
  outboundTransaction?: string | number
  beneficiary?: string
  address?: string
  bank?: Maybe<string> | undefined
  iban?: Maybe<string> | undefined
  swift?: Maybe<string> | undefined
  accountNumber?: Maybe<string> | undefined
  to?: string
  from?: Maybe<string> | undefined
  sent?: string
  accountName?: Maybe<string> | undefined
  accountIban?: Maybe<string> | undefined
  accId?: string
  trxId?: string
  transactionFeeDetails?: Maybe<string> | undefined
  recipientType?: Maybe<SwiftTransferRecipientType> | undefined
  comment?: Maybe<string>
  isLocal?: boolean
  createdAt?: string
  valueDate?: string
  executionType?: TransactionExecutionType
  priority?: TransactionPriorityType
  fromDetails?: Maybe<string> | undefined
  toDetails?: Maybe<string> | undefined
  xRate?: Maybe<number> | undefined
  reference?: Maybe<string> | undefined
  specifiedBeneficiary?: Maybe<string>
  suggestedBeneficiary?: Maybe<string> | undefined
  vban?: string | null | undefined
  senderName?: Maybe<string> | undefined
  senderBank?: Maybe<string> | undefined
  senderIban?: Maybe<string> | undefined
  senderSwift?: Maybe<string> | undefined
  relatedPaymentId?: Maybe<string | number> | undefined
  trxType?: Maybe<TransactionType>
  clientReference?: Maybe<string> | undefined
  additionalComment?: Maybe<string> | undefined
  initiatorName?: Maybe<string> | undefined
  standingOrderId?: Maybe<string> | undefined
  directDebitExecution?: Maybe<DirectDebitExecution> | undefined
  hash?: Maybe<string> | undefined
  fee?: Maybe<number> | undefined
  trxHash?: Maybe<string> | undefined
}

export type WalletProps = ActionSignatureProps & {
  address: string
  network: SupportedCryptoNetwork
  currency: Currency
  alias: string
  supportedCoins: Coin[]
}

export type CryptoTransactionRequestProps = ActionSignatureProps & {
  status: Maybe<TransactionRequestStatus>
  amount: string
  currency: AllCurrencies
  from: Maybe<string> | undefined
  to: Maybe<string> | undefined
  fromDetails: Maybe<string> | undefined
  toDetails: Maybe<string> | undefined
  rate: Maybe<number> | undefined
  slippageTolerance: Maybe<number> | undefined
  createdAt?: string
}

export type TransferProps = ActionSignatureProps & {
  txData?: TransactionData
}

export type AccountProps = ActionSignatureProps & {
  iban: string
  reason?: string
  contractSignatoriesCount?: number
  currency?: string | undefined
  name?: Maybe<string> | undefined
  dateOfBirth?: string | undefined
  nationality?: Maybe<Nationality> | undefined
  isVirtual?: Maybe<boolean> | undefined
  registeredAddress?: Maybe<Address> | undefined
}
export type CardholderProps = ActionSignatureProps & {
  name: string
  role?: string
  cardSpendingLimit?: number
}

export type BatchPaymentProps = ActionSignatureProps & {
  status?: Maybe<BatchPaymentStatusType>
  accountNumber?: string | undefined | null
  account?: string | undefined | null
  iban?: Maybe<string> | undefined
  successfulPayments?: number
  totalAmount?: string
  accId?: string
  creator?: Maybe<string>
}

export type DocumentProps = ActionSignatureProps & {
  type?: string
  mimeType?: string
  description?: string
  url?: string
}

export const getSignatures = (
  signatures: Maybe<Maybe<ActionSignature>[]> | undefined,
): SignatureProps[] => {
  return (signatures || []).map((item) => {
    const signatory = item?.signatory
    const person = signatory?.person
    return {
      id: item?.id,
      personId: person?.id,
      signatory: `${person?.firstName} ${person?.lastName} (${signatory?.signatoryRight})`,
      signatoryRight: signatory?.signatoryRight,
      status: item?.status || '',
      actionTaken: item?.signedAt,
    } as SignatureProps
  })
}

export const getTrxSignatures = (trxSignatures: ActionSignature[]): SignatureProps[] => {
  return (trxSignatures || []).map((item) => {
    const signatory = item?.signatory
    const person = signatory?.person
    return {
      id: item?.id,
      personId: person?.id,
      signatory: `${person?.firstName} ${person?.lastName} (${signatory?.signatoryRight})`,
      signatoryRight: signatory?.signatoryRight,
      status: item?.status || '',
      actionTaken: item?.signedAt,
    } as SignatureProps
  })
}

export const getUserSignature = (
  userId?: number,
  signatures?: SignatureProps[],
): SignatureProps | undefined => {
  return signatures?.filter((item) => item?.personId === userId)[0]
}

export const canSignOrReject = (userId?: number, signatures?: SignatureProps[]): boolean => {
  return (
    getUserSignature(userId, signatures)?.status === ActionSignatureStatus.Pending &&
    !some(signatures, (s) => s.status === ActionSignatureStatus.Reject)
  )
}

export const canSignAction = (action: Action): boolean => {
  const status = transformActionStatus(action)
  return status === ActionSignatureStatus.Pending
}

export const docDescriptionOrActionType = (action: Action): string => {
  const { type } = action

  switch (type) {
    case ActionType.DocumentSigning:
      if (!(action as DocumentAction).document) {
        return i18n.t('deletedDocument', 'Deleted document')
      }
      const docLabel = find(
        documentTypes,
        (doc) => doc.key === ((action as DocumentAction).document?.type as string),
      )?.label
      return i18n.t((action as DocumentAction).document?.type as string, docLabel)
    case ActionType.TransactionSigning:
      return `${i18n.t('transferTo', 'Transfer to')} ${
        ((action as TransactionAction).transaction as DebitTransaction)?.to?.name
      }`
    case ActionType.ContractDetailChangeSigning:
      return i18n.t('contractDetailsChange', 'Business details change')
    case ActionType.AccountOpeningSigning:
      const currency = (action as AccountAction)?.account?.currency
      return currency
        ? `${(action as AccountAction)?.account?.currency} ${i18n.t(
            'accountOpeningActionType',
            'account opening',
          )}`
        : i18n.t('accountOpeningActionType2', 'Account opening')
    case ActionType.AccountClosureSigning:
      return i18n.t('accountClosureActionType', 'Account Closure')
    case ActionType.CardIssueRequest:
      return i18n.t('cardIssueRequestActionType', 'New virtual card')
    case ActionType.ContractAuthorizedPersonRequest:
      return `${
        (action as AddContractAuthorizedPersonRequestAction).newAuthorizedPerson?.person?.firstName
      } ${
        (action as AddContractAuthorizedPersonRequestAction).newAuthorizedPerson?.person?.lastName
      } ${i18n.t('invitation', 'invitation')}`
    case ActionType.SpendingLimitRequest:
      return i18n.t('spendingLimitRequestActionType', 'Change virtual card spending limits')
    case ActionType.BatchPayment:
      return i18n.t('batchPayment', 'Batch payment')
    case ActionType.VibanNameMismatch:
      return i18n.t('inbVbanPay', 'Inbound VIBAN payment')
    case ActionType.ContractTerminationRequest:
      return i18n.t('contractTermination', 'Contract termination')
    case ActionType.ContractAuthorizedPersonDeactivation:
      return i18n.t('deactivateTeammate', 'Teammate deactivation')
    case ActionType.ActivateStandingOrder:
      return i18n.t('activateStandingOrder', 'Activate standing order')
    case ActionType.CancelStandingOrder:
      return i18n.t('cancelStandingOrder', 'Cancel standing order')
    case ActionType.ChangeAccountAlternativeAccountDetailsRequest:
      return i18n.t('changeAltAccount')
    case ActionType.TermsAndConditionsSigning:
      return i18n.t('termsAndConditions', 'Terms and Conditions')
    case ActionType.PaymentRefundRequest:
      return i18n.t('paymentRefundRequest', 'Payment Refund Request')
    case ActionType.ApproveDirectDebitB2BDebitMandate:
      return i18n.t('approveDirectDebitB2BDebitMandate', 'Approve Direct Debit')
    case ActionType.UpdateSpendingLimit:
      return i18n.t('updateSpendingLimit', 'Update Spending Limit')
    case ActionType.DisputeDirectDebit:
      return i18n.t('directDebitDispute', 'Direct Debit Dispute')
    case ActionType.DirectDebitBatchRequest:
      return i18n.t('directDebitBatchRequest', 'Direct Debit Batch Request')
    case ActionType.ApproveDirectDebitCreditMandate:
      return i18n.t('directiDebitMandate', 'Direct Debit Mandate')
    case ActionType.WalletSigning:
      return i18n.t('walletSigning', 'Crypto account opening')
    default:
      return startCase(type)
  }
}

export const convertActionType = (type: string): string => {
  switch (type) {
    case ActionType.DocumentSigning:
      return i18n.t('document', 'Document')
    case ActionType.ContractDetailChangeSigning:
    case ActionType.ContractTerminationRequest:
      return i18n.t('contractChange', 'Contract change')
    case ActionType.TransactionSigning:
    case ActionType.VibanNameMismatch:
    case ActionType.ActivateStandingOrder:
    case ActionType.CancelStandingOrder:
    case ActionType.DisputeDirectDebit:
    case ActionType.DirectDebitBatchRequest:
      return i18n.t('transfer', 'Transfer')
    case ActionType.BatchPayment:
      return i18n.t('transactionTypeOutbound', 'Outbound')
    case ActionType.ContractAuthorizedPersonRequest:
      return i18n.t('authPerson', 'New authorized person')
    case ActionType.ContractAuthorizedPersonDeactivation:
      return i18n.t('deactivatePerson', 'Deactivate person')
    case ActionType.TermsAndConditionsSigning:
      return i18n.t('eSignatureRequired', 'E-signature required')
    case ActionType.PaymentRefundRequest:
      return i18n.t('paymentFacilitator', 'Payment facilitation')
    case ActionType.RejectDirectDebit:
    case ActionType.ApproveDirectDebitB2BDebitMandate:
    case ActionType.PreapproveDirectDebit:
    case ActionType.ApproveDirectDebitCreditMandate:
      return i18n.t('directDebit', 'Direct Debit')
    default:
      return i18n.t('accChange', 'Account change')
  }
}

export const normalizeTypesForBE = (type: ActionsFilterTypesEnum): ActionType[] => {
  switch (type) {
    case ActionsFilterTypesEnum.Document:
      return [ActionType.DocumentSigning]
    case ActionsFilterTypesEnum.ContractChange:
      return [ActionType.ContractDetailChangeSigning, ActionType.ContractTerminationRequest]
    case ActionsFilterTypesEnum.Transfer:
      return [
        ActionType.TransactionSigning,
        ActionType.VibanNameMismatch,
        ActionType.ActivateStandingOrder,
        ActionType.CancelStandingOrder,
        ActionType.DirectDebitBatchRequest,
      ]
    case ActionsFilterTypesEnum.BatchPayment:
      return [ActionType.BatchPayment]
    case ActionsFilterTypesEnum.NewAuthorizedPerson:
      return [ActionType.ContractAuthorizedPersonRequest]
    case ActionsFilterTypesEnum.DeactivatePerson:
      return [ActionType.ContractAuthorizedPersonDeactivation]
    case ActionsFilterTypesEnum.AccountChange:
      return [
        ActionType.AccountOpeningSigning,
        ActionType.AccountClosureSigning,
        ActionType.CardIssueRequest,
        ActionType.SpendingLimitRequest,
        ActionType.ChangeAccountAlternativeAccountDetailsRequest,
        ActionType.UpdateSpendingLimit,
      ]
    case ActionsFilterTypesEnum.DirectDebit:
      return [
        ActionType.RejectDirectDebit,
        ActionType.ApproveDirectDebitCreditMandate,
        ActionType.ApproveDirectDebitB2BDebitMandate,
        ActionType.PreapproveDirectDebit,
      ]
    case ActionsFilterTypesEnum.PaymentFacilitation:
      return [ActionType.PaymentRefundRequest]
    default:
      return []
  }
}

export const economicProfileLabels = (enumStringValue: string): string => {
  switch (enumStringValue) {
    case economicProfileSourceFundsTypes.uboInvestments.key:
      return i18n.t('uboInvestments', 'UBO Investments')
    case economicProfileSourceFundsTypes.businessIncome.key:
      return i18n.t('businessIncome', 'Business Income')
    case economicProfileSourceFundsTypes.borrowedFunds.key:
      return i18n.t('borrowedFunds', 'Borrowed Funds')
    case economicProfileSourceFundsTypes.other.key:
      return i18n.t('other', 'Other')

    case economicProfileProducts.iban.key:
      return i18n.t('iban', 'IBAN')
    case economicProfileProducts.corporateCards.key:
      return i18n.t('corporateCards', 'Corporate Cards')
    case economicProfileProducts.virtualIban.key:
      return i18n.t('virtualIban', 'Virtual Iban')
    case economicProfileProducts.cryptocurrencyWallet.key:
      return i18n.t('cryptocurrencyWallet', 'Cryptocurrency wallet')
    case economicProfileProducts.paymentProcessing.key:
      return i18n.t('paymentProcessing', 'Payment Processing')
    case 'businessSectorConductingClientKYC':
      return i18n.t(
        'businessSectorConductingClientKYC',
        'Do you conduct KYC on any of your customers?',
      )
    case anticipatedAccountActivities.B2B2C.key:
      return i18n.t('B2B2C', 'Settlements from Payment Providers')
    case anticipatedAccountActivities.B2C.key:
      return i18n.t('B2C', 'Payments from/to Retail Customers')
    case anticipatedAccountActivities.corporateExpenses.key:
      return i18n.t(
        'corporateCardsBusinessTransactionalBanking',
        'Corporate/Business Transactional Banking',
      )
    case economicProfileAverageSizeTypes.upToHundred.key:
      return i18n.t('upTo100000', 'up to 100,000.00')
    case economicProfileAverageSizeTypes.upToFiveHundred.key:
      return i18n.t('upTo500000', 'up to 500,000.00')
    case economicProfileAverageSizeTypes.upToMillion.key:
      return i18n.t('upTo1000000', 'up to 1,000,000.00')
    case economicProfileAverageSizeTypes.aboveMillion.key:
      return i18n.t('above1000000', 'above 1,000,000.00')
    case economicProfileAverageSizeTypes.lessThanFiftyThousand.key:
      return i18n.t('upTo50000', 'up to 50,000')
    case economicProfileAverageSizeTypes.fiftyToHundredFiftyThousand.key:
      return i18n.t('upTo150000', '50,000 - 150,000')
    case economicProfileAverageSizeTypes.hundredFiftyToThreeHundredThousand.key:
      return i18n.t('upTo300000', '150,000 - 300,000')
    case economicProfileAverageSizeTypes.threeHundredToFiveHundredThousand.key:
      return i18n.t('300000To500000', '300,000 - 500,000')
    case economicProfileAverageSizeTypes.fiveHundredToSevenHundredFiftyThousand.key:
      return i18n.t('upTo750000', '500,000 - 750,000')
    case economicProfileAverageSizeTypes.sevenHundredFiftyThousandToOneMill.key:
      return i18n.t('between750000And1000000', '750,000 - 1,000,000')
    case economicProfileAverageSizeTypes.oneToTwoMillion.key:
      return i18n.t('upTo2000000', '1,000,000 - 2,000,000')
    case economicProfileAverageSizeTypes.twoToFiveMillion.key:
      return i18n.t('upTo5000000', '2,000,000 - 5,000,000')
    case economicProfileAverageSizeTypes.aboveFiveMillion.key:
      return i18n.t('above5000000', 'above 5,000,000')

    case economicProfileTransactionSourceDestinationTypes.paymentsGoods.key:
      return i18n.t('paymentsForGoodsAndServices', 'Payment for goods and services')
    case economicProfileTransactionSourceDestinationTypes.administrationFees.key:
      return i18n.t('administrationFees', 'Administration fees')
    case economicProfileTransactionSourceDestinationTypes.payroll.key:
      return i18n.t('payroll', 'Payroll')
    case economicProfileTransactionSourceDestinationTypes.fxOperations.key:
      return i18n.t('fxOperations', 'FX Operations')
    case economicProfileTransactionSourceDestinationTypes.cryptoOperations.key:
      return i18n.t('cryptoOperations', 'Crypto Operations')
    case economicProfileTransactionSourceDestinationTypes.other.key:
      return i18n.t('other', 'Other')

    case economicProfileEmploymentStatus.businessOwner.key:
      return i18n.t('businessOwner', 'Business Owner')
    case economicProfileEmploymentStatus.employee.key:
      return i18n.t('employee', 'Employee')
    case economicProfileEmploymentStatus.selfEmployedSoleTrader.key:
      return i18n.t('selfEmployedSoleTrader', 'Self-employed/Sole-Trader')
    case economicProfileEmploymentStatus.contractorFreelancer.key:
      return i18n.t('contractorFreelancer', 'Contractor/Freelancer')
    case economicProfileEmploymentStatus.unemployed.key:
      return i18n.t('unemployed', 'Unemployed')

    case economicProfileSourceFundsTypes.salary.key:
      return i18n.t('salary', 'Salary')
    case economicProfileSourceFundsTypes.invoicedWork.key:
      return i18n.t('invoicedWork', 'Invoiced work')
    case economicProfileSourceFundsTypes.dividends.key:
      return i18n.t('dividends', 'Dividends/Capital withdrawals from owned business')
    case economicProfileSourceFundsTypes.sellingPersonalAssets.key:
      return i18n.t('sellingPersonalAssets', 'Selling Personal Assets')
    case economicProfileSourceFundsTypes.gifts.key:
      return i18n.t('gifts', 'Gifts')
    case economicProfileSourceFundsTypes.inheritances.key:
      return i18n.t('inheritances', 'Inheritances')
    case economicProfileSourceFundsTypes.loans.key:
      return i18n.t('loans', 'Loans')
    case economicProfileSourceFundsTypes.retirementFunds.key:
      return i18n.t('retirementFunds', 'Retirement funds')
    case economicProfileSourceFundsTypes.rentalIncome.key:
      return i18n.t('rentalIncome', 'Rental Income')
    case economicProfileSourceFundsTypes.onlinePlatforms.key:
      return i18n.t('onlinePlatforms', 'Online Platforms (e.g. Social Media)')
    case economicProfileSourceFundsTypes.lotteryWinnings.key:
      return i18n.t('lotteryWinnings', 'Lottery Winnings')
    case economicProfileSourceFundsTypes.gamblingWinnings.key:
      return i18n.t('gamblingWinnings', 'Gambling Winnings')
    case economicProfileSourceFundsTypes.notOwnDividends.key:
      return i18n.t('notOwnDividends', 'Dividends/interest not from own business')
    case economicProfileSourceFundsTypes.otherInvestmentActivity.key:
      return i18n.t('otherInvestmentActivity', 'Other Investment Activity')

    case economicProfileAccountBalance.upToTenThousand.key:
      return i18n.t('upToTenThousand', '0 - 10,000')
    case economicProfileAccountBalance.tenToHundrerdThousand.key:
      return i18n.t('tenToHundrerdThousand', '10,000 - 100,000')
    case economicProfileAccountBalance.moreThanHundrerdThousand.key:
      return i18n.t('moreThanHundrerdThousand', '100,000+')

    case economicProfileAverageSizeTypes.upToThreeThousand.key:
      return i18n.t('upToThreeThousand', '0 - 3,000')
    case economicProfileAverageSizeTypes.threeToTenThousand.key:
      return i18n.t('threeToFifteenThousand', '3,000 - 10,000')
    case economicProfileAverageSizeTypes.moreThanTenThousand.key:
      return i18n.t('moreThanTenThousand', '10,000+')

    case 'eur':
      return i18n.t('EUR', 'EUR')
    case 'chf':
      return i18n.t('CHF', 'CHF')

    default:
      return enumStringValue
  }
}

export const actionAmount = (typeAction: string | undefined, action: Action): string => {
  switch (typeAction) {
    case 'TransactionAction':
      return `${currencyFormat(
        Math.abs((action as TransactionAction)?.transaction?.value as number),
      )}
      ${(action as TransactionAction).transaction?.currency}`
    case 'BatchPaymentActionType':
      const totalAmount = (action as BatchPaymentActionType)?.batchPayment?.calculatedValidTotal
      return `${currencyFormat(Math.abs(totalAmount as number))} ${
        (action as BatchPaymentActionType)?.batchPayment?.account?.currency
      }`
    case 'RefundPaymentRequestAction':
      const amount = (action as RefundPaymentRequestAction)?.refundPayment?.amount
      return `${currencyFormat(Math.abs(amount as number))} ${
        (action as RefundPaymentRequestAction)?.refundPayment?.currency
      }`
    default:
      return '-'
  }
}
