import { Box, ClickAwayListener, SvgIconTypeMap, Tooltip } from '@material-ui/core'
import React, { FC, ReactElement } from 'react'
import { useControlledTooltip } from '../../../hooks/useContolledTooltip'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  popperWrap: {
    pointerEvents: 'auto',
    maxHeight: '40vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      outline: '1px solid #efefef',
      borderRadius: '0.05em',
    },
  },
  iconWrap: {
    display: 'flex',
    '& svg': {
      alignSelf: 'center',
    },
  },
}))
const ControlledIconTooltip: FC<
  Omit<TooltipProps, 'children'> &
    (
      | { Icon: OverridableComponent<SvgIconTypeMap>; children?: never }
      | { Icon?: never; children: ReactElement }
    ) & { iconColor?: 'inherit' | 'primary' | 'secondary' | 'action' | 'error' | 'disabled' }
> = ({ Icon, title, iconColor, children, ...rest }) => {
  const {
    tooltipListeners,
    tooltipOpen,
    handleTooltipClose,
    handleTooltipOpen,
  } = useControlledTooltip()
  const classes = useStyles()
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        open={tooltipOpen}
        disableFocusListener
        disableTouchListener
        disableHoverListener
        {...tooltipListeners}
        arrow
        title={
          <Box {...tooltipListeners} className={classes.popperWrap}>
            {title}
          </Box>
        }
        {...rest}
      >
        <div className={classes.iconWrap}>
          {Icon ? (
            <Icon color={iconColor ? iconColor : 'primary'} onClick={handleTooltipOpen} />
          ) : (
            children
          )}
        </div>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default ControlledIconTooltip
