import { Trans, useTranslation } from 'react-i18next'
import {
  CountryCode,
  NationalNumber,
  parsePhoneNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js/max'
import { toast } from 'react-toastify'
import { Controller, useFormContext } from 'react-hook-form'
import { filter, find, toLower } from 'lodash'

import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react'
import {
  FormControl,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Box,
  Button,
  FormHelperText,
  makeStyles,
  Paper,
  Grow,
  Popper,
  ClickAwayListener,
  List,
} from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp, Check, Search } from '@material-ui/icons'

import { countries } from '@klarpay/enums'

import { BaseEnumWithKey, countriesArray, prefixCode, PhonePrefixStyles } from './types'
import { Maybe } from '../../../graphql'
import { ControlledTextFieldProps } from '../../../types'
import { FlagIcon } from '../FlagIcon'
import { DEFAULT_COUNTRY_CALLING_CODE } from '../../../constants'
import { handleLatinKeysDown } from '../../../utils'

const usePrefixInputStyles = ({ phonePrefixCount }: PhonePrefixStyles) =>
  makeStyles((theme) => ({
    phonePrefixStyle: {
      flexBasis: phonePrefixCount === 4 ? 40 : phonePrefixCount >= 5 ? 48 : 32,
      [theme.breakpoints.down(480)]: {
        flexBasis: phonePrefixCount === 4 ? 40 : phonePrefixCount >= 5 ? 48 : 35,
      },
    },
  }))()

const useStyles = (componentDisabled: boolean) =>
  makeStyles((theme) => ({
    root: {},
    popper: {
      zIndex: 2,
      width: '100%',
    },
    paper: {
      marginRight: 0,
      maxHeight: 400,
      maxWidth: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      background: '#FFFFFF',
      borderRadius: 0,
      boxShadow: '0 3.5px 14px rgba(0, 0, 0, 0.2)',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        outline: '1px solid #efefef',
        borderRadius: '0.05em',
      },
    },
    countryPhone: {
      margin: theme.spacing(3, 0, 2.5),
      display: 'flex',
      alignItems: 'flex-end',
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      '& .MuiInputLabel-formControl': {
        zIndex: 0,
      },
    },
    searchField: {
      width: '100%',
      '& .MuiTextField-root': {
        margin: theme.spacing(0.5, 1, 1, 1),
        padding: theme.spacing(0),
        minHeight: 'auto',
      },
      '& .MuiInput-root': {
        paddingLeft: theme.spacing(1.5),
      },
      '& .MuiInputBase-input': {
        height: 48,
        boxSizing: 'border-box',
      },
      '& .MuiInput-underline:before': {
        height: '100%',
        border: '1px solid #D9D9D9',
      },
      '& .MuiSvgIcon-root': {
        fill: '#999',
      },
    },
    inputWrap: {
      display: 'flex',
      alignItems: 'flex-end',
      position: 'relative',
      width: '100%',
    },
    phonePrefix: {
      flexBasis: 32,
      '& .MuiInput-root': {
        minHeight: '48px',
        alignItems: 'flex-end',
      },
      '& .MuiInputBase-input': {
        width: 'auto',
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 20,
        lineHeight: '24px',
        color: componentDisabled ? 'rgba(0, 0, 0, 0.38)' : 'black',
      },
      '& .MuiInput-formControl.Mui-disabled': {
        backgroundColor: 'transparent',
      },
      '& .MuiInput-underline.Mui-disabled': {
        '&:before': {
          borderBottomStyle: 'solid',
        },
      },
    },
    phoneInput: {
      flexGrow: 3,
      flexBasis: 'calc(100% - 68px - 32px)',
      minHeight: 'auto',
      position: 'static',
      '& > label': {
        [theme.breakpoints.down(370)]: {
          width: 200,
        },
      },
      '& .MuiInputLabel-shrink + .MuiInput-root .MuiInput-input': {
        paddingTop: theme.spacing(2.5),
      },
      '& .MuiInputLabel-formControl': {
        transform: 'translate(4px, 12px) scale(0.857)',
        transformOrigin: 'top left',
        padding: 0,
      },
      '& .MuiInputBase-input': {
        padding: theme.spacing(2.5, 0, 0, 0),
      },
      '& .MuiInputLabel-shrink + .Mui-disabled': {
        backgroundColor: 'transparent',
      },
    },
    countryFlag: {
      flex: '0 0',
      cursor: 'pointer',
      minWidth: 68,
      minHeight: 64,
      background: 'none',
      padding: theme.spacing(1.5, 0.5, 1.5, 1.5),
      boxShadow: 'inset -1px -2px 0px -1px #ccc',
      // borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      position: 'relative',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'inset -1px -3px 0px -1px #000',
      },
      '& .MuiButton-label img': {
        fontSize: '1.25em !important',
      },
      '& .MuiSvgIcon-root': {
        color: '#999',
      },
    },
    item: {
      padding: theme.spacing(1, 1, 1, 1),
      '& .MuiListItemIcon-root': {
        color: '#000',
        minWidth: '1.5em',
        marginRight: theme.spacing(2),
      },
      '& .flag-icon-lg': {
        fontSize: '1.5em',
      },
    },
    errorFlag: {
      boxShadow: 'inset -1px -3px 0px -1px #EF2828',
    },
    redError: {
      color: '#EF2828',
    },
    description: {
      color: '#262626',
    },
  }))()

function checkPhone(value: string, countryKey: string) {
  const minNationalNumbLength = 6
  const maxNationalNumbLength = 15

  if (countryKey.toUpperCase() === 'AQ') {
    // handle Antarctica numbers
    const number = value.substring(5)
    return number.length >= minNationalNumbLength && number.length < maxNationalNumbLength
  }
  try {
    const phoneNumber = parsePhoneNumber(value, countryKey.toUpperCase() as CountryCode | undefined)
    const nationalNumberPath = phoneNumber.nationalNumber.toString()
    return (
      phoneNumber.isValid() &&
      nationalNumberPath.length >= minNationalNumbLength &&
      nationalNumberPath.length < maxNationalNumbLength
    )
  } catch (e) {
    return false
  }
}

type MenuListCompositionProps = {
  countryCode: string | undefined
  error: boolean
  countryKey: string | undefined
  setCountryKey: React.Dispatch<React.SetStateAction<string | undefined>>
  value: string | NationalNumber
  name: string
  componentDisabled?: boolean
}

const MenuListComposition: FC<MenuListCompositionProps> = ({
  countryCode,
  error,
  countryKey,
  setCountryKey,
  value,
  name,
  componentDisabled = false,
}) => {
  const { t } = useTranslation()
  const { setValue, setError, clearErrors } = useFormContext()
  const classes = useStyles(componentDisabled)
  const [open, setOpen] = useState<boolean>(false)
  // return focus to the button when we transitioned from !open → open
  const prevOpen = React.useRef(open)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [search, setSearch] = useState<string>('')

  const handleToggle = () => {
    setOpen((prevState) => !prevState)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const clearSearchField = () => {
    if (!open) {
      setSearch('')
    }
  }

  const changeCountry = useCallback(
    (key: string) => {
      setCountryKey(key)
      setOpen(false)
      if (checkPhone(prefixCode(key) + ' ' + value, key)) {
        clearErrors(name)
        setValue(name, prefixCode(key) + ' ' + value, {
          shouldValidate: true,
          shouldDirty: true,
        })
      } else {
        value.length !== 0 &&
          setError(name, {
            type: 'manual',
            message: t('incorrectPhoneNumberFormat', 'Incorrect phone number format'),
          })
      }
    },
    [clearErrors, name, setCountryKey, setError, setValue, t, value],
  )

  const filteredCountries = useMemo(
    () => filter([...countriesArray], (c) => toLower(c.label).includes(toLower(search))),
    [search],
  )

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    return setSearch(event.target.value)
  }, [])

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Box className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={`${classes.countryFlag} ${error ? classes.errorFlag : ''}`}
        disabled={componentDisabled}
        data-test="countryPhoneSelect"
      >
        {countryCode && <FlagIcon code={countryCode} />}
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className={classes.popper}
        placement="bottom-start"
        onTransitionEnd={clearSearchField}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement !== 'bottom-start' ? 'left bottom' : 'left top' }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <List id="menu-list-grow">
                  <FormControl className={classes.searchField}>
                    <TextField
                      InputProps={{ startAdornment: <Search fontSize={'small'} /> }}
                      onChange={handleSearch}
                      onKeyDown={handleLatinKeysDown}
                      value={search}
                      variant={'standard'}
                      autoFocus
                      placeholder={t('search', 'Search')}
                      data-test="autotest-phoneCountryInput"
                    />
                  </FormControl>
                  {filteredCountries?.map((countryFilter: BaseEnumWithKey) => (
                    <ListItem
                      key={countryFilter.value}
                      onClick={() => changeCountry(countryFilter.value)}
                      button
                      className={classes.item}
                      data-test="autotest-countryPhoneCountry"
                    >
                      <ListItemIcon>
                        <FlagIcon code={toLower(countryFilter.value)} size={'2em'} />
                      </ListItemIcon>
                      <ListItemText>
                        <Trans i18nKey={countryFilter.label} defaults={countryFilter.label} />
                      </ListItemText>
                      {countryKey === countryFilter.value && <Check fontSize={'small'} />}
                    </ListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export const CountryPhoneSelect: FC<
  {
    name: string
    label: string
    countryDefault?: string
    defaultValue?: Maybe<string> | undefined
    componentDisabled?: boolean
    autoClear?: boolean
    skipToastMessage?: boolean
    description?: string
  } & Partial<ControlledTextFieldProps>
> = ({
  name = '',
  label = '',
  countryDefault = DEFAULT_COUNTRY_CALLING_CODE,
  defaultValue = '',
  componentDisabled = false,
  autoClear = false,
  rules,
  skipToastMessage = false,
  description,
  ...rest
}) => {
  const { t } = useTranslation()
  const { control, setValue, errors, setError, clearErrors, watch } = useFormContext()
  const error = errors ? errors[name] : null
  const classes = useStyles(componentDisabled)
  const currentValue = watch(name)
  const fieldCountry = watch('country')

  const [phoneNumberEdited, setPhoneNumberEdited] = useState<NationalNumber | string>(
    defaultValue || '',
  )
  const [countryKey, setCountryKey] = useState<string | undefined>(countryDefault)

  const { phonePrefixStyle } = usePrefixInputStyles({
    phonePrefixCount: prefixCode(countryKey).length,
  })

  // check selected country
  useEffect(() => {
    if (!fieldCountry) {
      return
    }
    if (!autoClear && !phoneNumberEdited) {
      setCountryKey(fieldCountry.toUpperCase())
    }
    if (autoClear) {
      setCountryKey(fieldCountry.toUpperCase())
      setPhoneNumberEdited('')
      setValue(name, '')
    }
    return () => {
      if (autoClear) {
        setCountryKey('')
        setValue(name, '')
        setPhoneNumberEdited('')
      }
    }
  }, [fieldCountry])

  useEffect(() => {
    if (!!currentValue) {
      try {
        const clearedString = (currentValue as string)
          .replace(/\+/g, (match, offset) => (offset === 0 ? match : ''))
          .replaceAll(' ', '')

        const validNumCheck = isValidPhoneNumber(clearedString)
        const phoneDetails = parsePhoneNumber(clearedString.trim())
        if (validNumCheck) {
          setCountryKey(phoneDetails.country)
          setPhoneNumberEdited(
            phoneDetails.number.replace(`${prefixCode(phoneDetails.country)}`, ''),
          )
        }
      } catch (e) {}
    } else {
      setValue(name, '')
      setPhoneNumberEdited('')
      setCountryKey(DEFAULT_COUNTRY_CALLING_CODE)
    }
  }, [currentValue])

  const countryCode = useMemo(() => {
    const key = toLower(countryKey)
    return find(countries, (c) => c.value.toLowerCase() === key)
  }, [countryKey])

  const handleChangePhoneNumber = useCallback<
    ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (value) => {
      if (!!countryKey && countryKey.length > 0) {
        const normalizedNumber = value.target.value.replace(/\D/g, '')
        setPhoneNumberEdited(normalizedNumber)
        if (!checkPhone(prefixCode(countryKey) + ' ' + value.target.value, countryKey)) {
          setError(name, {
            type: 'manual',
            message: t('incorrectPhoneNumberFormat', 'Incorrect phone number format'),
          })
        } else {
          clearErrors(name)
          setValue(name, prefixCode(countryKey) + value.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
      } else {
        setError(name, {
          type: 'manual',
          message: t('countryForPhonePrefixMustSet', 'country for phone prefix must be set'),
        })
      }
    },
    [countryKey, setError, name, t, clearErrors, setValue],
  )

  const handleBlurPhoneNumber = useCallback<
    ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (event) => {
      const { value } = event.target
      if (!value) return
      if (!!countryKey && countryKey.length > 0) {
        if (checkPhone(prefixCode(countryKey) + ' ' + value, countryKey)) {
          clearErrors(name)
        } else {
          setValue(name, prefixCode(countryKey) + ' ' + value, {
            shouldDirty: true,
          })
          !skipToastMessage &&
            toast.error(
              t(
                'errorInSavedDataForPhoneNumber',
                'Previously saved value for phone number is not correct',
              ),
            )
        }
      } else {
        setError(name, {
          type: 'manual',
          message: t('countryForPhonePrefixMustSet', 'country for phone prefix must be set'),
        })
      }
    },
    [countryKey, setError, name, t, clearErrors],
  )

  const renderMenuListComposition = useMemo(
    () => (
      <MenuListComposition
        countryCode={countryCode?.value?.toLowerCase()}
        countryKey={countryKey}
        setCountryKey={setCountryKey}
        value={phoneNumberEdited}
        name={name}
        error={!!error}
        componentDisabled={componentDisabled}
      />
    ),
    [countryCode?.value, countryKey, phoneNumberEdited, name, error, componentDisabled],
  )

  return (
    <Box className={classes.countryPhone} data-test={`autotest-${name}`}>
      {renderMenuListComposition}
      <Box className={classes.inputWrap}>
        <TextField
          id="prefixCode"
          type="text"
          value={prefixCode(countryKey)}
          error={!!error}
          className={`${classes.phonePrefix} ${phonePrefixStyle}`}
          disabled
          aria-readonly={true}
          data-test={`autotest-${name}PrefixCode`}
        />

        <Controller
          id="phoneNumberEdited"
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ onChange, ref }) => {
            return (
              <TextField
                type="text"
                value={phoneNumberEdited}
                onChange={(e) => {
                  onChange(e)
                  handleChangePhoneNumber(e)
                }}
                onBlur={handleBlurPhoneNumber}
                inputRef={ref}
                label={label}
                error={!!error}
                className={classes.phoneInput}
                disabled={componentDisabled}
                data-test="autotest-phoneInput"
                {...rest}
              />
            )
          }}
        />
      </Box>
      {!!error && (
        <FormHelperText data-test="errorPhone" className={classes.redError}>
          {error.message}
        </FormHelperText>
      )}
      {description && !error && (
        <FormHelperText className={classes.description}>{description}</FormHelperText>
      )}
    </Box>
  )
}
